<template>
  <v-app id="app">
    <div v-if="!isAppLoaded">
      <PtrLoading />
    </div>
    <router-view />
  </v-app>
</template>

<script>
import PtrLoading from "@/components/shared/PtrLoading.vue";

export default {
  name: "App",
  components: {
    PtrLoading
  },
  computed: {
    isAppLoaded() {
      return this.$store.state.isAppLoaded;
    }
  },
  mounted() {
    this.$store.commit("SET_HISTORY_LENGTH", window.history.length);
  }
};
</script>

<style lang="scss">
@import "@/scss/global.scss";
@import url("https://unpkg.com/maplibre-gl@1.15.3/dist/maplibre-gl.css");
.crosshair {
  .mapboxgl-canvas-container {
    cursor: crosshair !important;
  }
}
.move {
  .mapboxgl-canvas-container {
    cursor: move !important;
  }
}
.all-scroll {
  .mapboxgl-canvas-container {
    cursor: all-scroll !important;
  }
}

.popup-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  padding: 10px 0;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
    background: rgb(247, 247, 247);
  }
}

.options-popup .mapboxgl-popup-content,
.maplibregl-popup-content {
  width: 200px;
  font-size: 15px;
  text-align: center;
  padding: 5px;
}
</style>
