import vm from "../main";
import axios from "./PointrCloudAxios";
import Helpers from "@/helpers/Helpers";

export default class LevelService {
  static async getLevels(buildingInternalIdentifier) {
    let response;
    let levels;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.get(`/buildings/${buildingInternalIdentifier}/levels/draft`);
    } catch (error) {
      console.error(error);
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    levels = response?.data?.results?.sort(Helpers.sortContent);
    return levels;
  }

  static async getLevel(buildingInternalIdentifier, levelIndex) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.get(`/buildings/${buildingInternalIdentifier}/levels/${levelIndex}/draft`);
    } catch (error) {
      console.error(error);
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    return response?.data?.result;
  }

  static async createLevel(buildingInternalIdentifier, levelIndex, level) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.post(`/buildings/${buildingInternalIdentifier}/levels/${levelIndex}`, level);
    } catch (error) {
      console.error(error);
      return error?.response?.data?.error;
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    return response?.data;
  }

  static async updateLevel(buildingInternalIdentifier, levelIndex, level) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.patch(`/buildings/${buildingInternalIdentifier}/levels/${levelIndex}`, level);
    } catch (error) {
      console.error(error);
      return error?.response?.data?.error;
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    return response?.data;
  }

  static async deleteLevel(buildingInternalIdentifier, levelIndex) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.delete(`/buildings/${buildingInternalIdentifier}/levels/${levelIndex}`);
    } catch (error) {
      console.error(error);
      return error?.response?.data?.error;
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    return response?.data;
  }

  static async bulkUpload(siteInternalIdentifier, buildingInternalIdentifier, levelIndex, featureCollection) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    // TODO: Move this task to somewehere else?
    // generate fid for each feature
    // add default typeCode if there isn't one
    featureCollection.features = featureCollection.features.map((feature) => {
      if (feature.properties.fid === undefined || feature.properties.fid === "") {
        feature.properties.fid = Helpers.generateUuid();
      }
      if (feature.properties.typeCode === undefined || feature.properties.typeCode === "") {
        feature.properties.typeCode = "undefined";
      }
      feature.properties.bid = Number(buildingInternalIdentifier);
      feature.properties.sid = Number(siteInternalIdentifier);
      feature.properties.lvl = Number(levelIndex);
      return feature;
    });
    try {
      response = await axios.post(
        `/buildings/${buildingInternalIdentifier}/levels/${levelIndex}/bulk`,
        featureCollection,
        {
          headers: {
            isValidationIsClosed: 1
          }
        }
      );
    } catch (error) {
      response = error.response;
      console.error(error);
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    return response?.data;
  }
  static async convertBase64ToGeojson(request) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.post(`/tools/cad-to-geojson`, request);
      return response?.data;
    } catch (error) {
      response = error.response;
      console.error(error);
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }

    return response?.data;
  }

  static async uploadOriginalFloorPlan(
    clientInternalIdentifier,
    siteInternalIdentifier,
    buildingInternalIdentifier,
    levelIndex,
    fileInformation
  ) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.post(
        `/clients/${clientInternalIdentifier}/sites/${siteInternalIdentifier}/buildings/${buildingInternalIdentifier}/levels/${levelIndex}/original-floor-plan`,
        fileInformation
      );
    } catch (error) {
      console.error(error);
      return error?.response?.data?.error;
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }

    return response?.data;
  }

  static async geoTransform(request) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.post(`/tools/geotransform`, request);
      return response?.data;
    } catch (error) {
      response = error.response;
      console.error(error);
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    return response?.data;
  }

  static async startAIMapping(mapJob) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.post(`/map-jobs`, mapJob);
    } catch (error) {
      console.error(error);
      return error?.response?.data?.error;
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }

    return response?.data;
  }

  static async applyContent(clientInternalIdentifier, siteInternalIdentifier, buildingInternalIdentifier, levelIndex) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.post(
        `/clients/${clientInternalIdentifier}/sites/${siteInternalIdentifier}/buildings/${buildingInternalIdentifier}/levels/${levelIndex}/apply-content`
      );
    } catch (error) {
      console.error(error);
      return error?.response?.data?.error || error?.response?.data;
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }

    return response?.data;
  }

  static async deleteMapJob(mapJobId) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.delete(`/map-jobs/${mapJobId}`);
    } catch (error) {
      console.error(error);
      return error?.response?.data?.error;
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }

    return response?.data;
  }

  static async getMapJobDetails(mapJobId) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.get(`/map-jobs/${mapJobId}`);
    } catch (error) {
      console.error(error);
      return error?.response?.data?.error;
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }

    return response?.data;
  }

  /**
   * Endpoint : api/v8/tools/change-anchors
     HttpVerb : POST
     oldAnchorAFeature: Feature Object of A anchor
     oldAnchorBFeature: Feature Object of B anchor
     newAnchorACoordinates: [lon,lat] of new A
     newAnchorBCoordinates: [lon,lat] of new B
  */
  static async changeAnchors(request) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.post(`/tools/change-anchors`, request);
      return response?.data;
    } catch (error) {
      response = error.response;
      console.error(error);
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    return response?.data;
  }

  static async calculateAnchors(request) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.post(`/tools/calculate-anchors`, request);
    } catch (error) {
      response = error.response;
      console.error(error);
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    return response?.data;
  }

  static async getLevelStatuses(clientInternalIdentifier, siteInternalIdentifier, buildingInternalIdentifier) {
    let response;
    try {
      response = await axios.get(
        `/clients/${clientInternalIdentifier}/sites/${siteInternalIdentifier}/buildings/${buildingInternalIdentifier}/level-statuses`
      );
    } catch (error) {
      response = error.response;
      console.error(error);
    }
    return response?.data?.result;
  }

  static async shareFeedback(mapJobId, floorPlanId, request) {
    let response;
    vm.$store.commit("ADD_BACKGROUND_LOADING_REQUEST");
    try {
      response = await axios.post(`/map-jobs/${mapJobId}/floorplan/${floorPlanId}/feedback`, request);
    } catch (error) {
      response = error.response;
      console.error(error);
    } finally {
      vm.$store.commit("REMOVE_BACKGROUND_LOADING_REQUEST");
    }
    return response?.data?.result;
  }

  static async revertAIMapping(
    clientInternalIdentifier,
    siteInternalIdentifier,
    buildingInternalIdentifier,
    levelIndex
  ) {
    let response;
    vm.$store.commit("ADD_BACKGROUND_LOADING_REQUEST");
    try {
      response = await axios.post(
        `/clients/${clientInternalIdentifier}/sites/${siteInternalIdentifier}/buildings/${buildingInternalIdentifier}/levels/${levelIndex}/revert-content`
      );
    } catch (error) {
      response = error.response;
      console.error(error);
    } finally {
      vm.$store.commit("REMOVE_BACKGROUND_LOADING_REQUEST");
    }
    return response?.data?.result;
  }
}
