import Helpers from "@/helpers/Helpers";

import clientRoutes from "./clientRoutes";
import siteRoutes from "./siteRoutes";
import buildingRoutes from "./buildingRoutes";
import levelRoutes from "./levelRoutes";

export default [
  ...levelRoutes,
  ...buildingRoutes,
  ...siteRoutes,
  ...clientRoutes,
  // CONTENT PICKER
  {
    path: "clients/:clientId/sites/:siteId/buildings/:buildingId/levels/:levelId/select",
    name: "ContentPicker",
    component: Helpers.lazyLoad("ContentPickerView", "views/content/contentPicker"),
    meta: { requiresAuth: true }
  },
  // CONTENT LIST
  {
    path: "clients/:clientId/sites/:siteId/buildings/:buildingId/levels/:levelId/:contentType(\\pois)/:featureId?",
    name: "PoiList",
    component: Helpers.lazyLoad("PoiListView", "views/content/mapDesigner"),
    meta: { requiresAuth: true }
  },
  {
    path: "clients/:clientId/sites/:siteId/buildings/:buildingId/levels/:levelId/:contentType(\\geofences)/:featureId?",
    name: "GeofenceList",
    component: Helpers.lazyLoad("GeofenceListView", "views/content/mapDesigner"),
    meta: { requiresAuth: true }
  },
  {
    path: "clients/:clientId/sites/:siteId/buildings/:buildingId/levels/:levelId/:contentType(\\mapObjects)/:featureId?",
    name: "MapObjectList",
    component: Helpers.lazyLoad("MapObjectListView", "views/content/mapDesigner"),
    meta: { requiresAuth: true }
  },
  {
    path: "clients/:clientId/sites/:siteId/buildings/:buildingId/levels/:levelId/:contentType(\\iot-devices)/:featureId?",
    name: "BeaconList",
    component: Helpers.lazyLoad("BeaconListView", "views/content/mapDesigner"),
    meta: { requiresAuth: true }
  },
  {
    path: "clients/:clientId/sites/:siteId/buildings/:buildingId/layers/:levelId/:contentType(\\wayfinding-network)/:featureId?",
    name: "WayfindingNetwork",
    component: Helpers.lazyLoad("WayfindingNetworkView", "views/content/mapDesigner"),
    meta: { requiresAuth: true }
  },
  {
    path: "preview",
    name: "Preview",
    component: Helpers.lazyLoad("ContentView", "views"),
    meta: { requiresAuth: true }
  }
];
