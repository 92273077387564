<template>
  <v-btn
    v-if="status !== PreviewStatuses.NO_CHANGE"
    id="preview-stage-button"
    :color="status === PreviewStatuses.REVIEW_READY ? 'success' : 'primary'"
    class="stage-button"
    :disabled="status === PreviewStatuses.STAGE_IN_PROGRESS || status === PreviewStatuses.PUBLISH_IN_PROGRESS"
    depressed
    @click="stageButtonHandler"
  >
    <template #default>
      <span v-if="status === PreviewStatuses.READY_TO_STAGE">
        {{ $t("stage-ready") }}
      </span>
      <span v-else-if="status === PreviewStatuses.STAGE_IN_PROGRESS">
        {{ $t("stage-in-progress") }}
        <LoadingSpinner />
      </span>
      <span v-else-if="status === PreviewStatuses.REVIEW_READY">
        {{ $t("stage-review") }}
      </span>
      <span v-else-if="status === PreviewStatuses.PUBLISH_IN_PROGRESS">
        {{ $t("publish-in-progress") }}
        <LoadingSpinner />
      </span>
    </template>
  </v-btn>
</template>

<script>
import PreviewService from "@/services/PreviewService";
import PreviewStatuses from "@/constants/PreviewStatuses";
import LoadingSpinner from "@/components/shared/LoadingSpinner";
import CustomToast from "@/components/shared/CustomToast.vue";
import { mapState } from "vuex";

export default {
  name: "StageButton",
  components: {
    LoadingSpinner
  },
  data: () => ({
    statusInterval: undefined,
    status: PreviewStatuses.NO_CHANGE
  }),
  computed: {
    ...mapState("MAP", ["currentClient"]),
    PreviewStatuses() {
      return PreviewStatuses;
    }
  },
  watch: {
    status(newStatus, oldStatus) {
      if (oldStatus === PreviewStatuses.PUBLISH_IN_PROGRESS) {
        this.$toast.success({
          component: CustomToast,
          props: { message: this.$t("publish-completed") }
        });
      }
    },
    currentClient() {
      this.updateStatus();
    }
  },
  created() {
    if (this.currentClient) {
      this.updateStatus();
    }
    this.statusInterval = setInterval(() => {
      this.updateStatus();
    }, 30000);
  },

  beforeDestroy() {
    clearInterval(this.statusInterval);
  },

  methods: {
    async stageButtonHandler() {
      if (this.status === PreviewStatuses.READY_TO_STAGE) {
        await PreviewService.startPreviewConversion(this.currentClient);
        this.updateStatus();
      } else if (this.status === PreviewStatuses.REVIEW_READY) {
        this.$router.push({ name: "Preview" }).catch((e) => console.log(e.message));
      }
    },
    async updateStatus() {
      const previewStatus = await PreviewService.getStatus(this.currentClient);
      this.$store.commit("PREVIEW_MAP/SET_PREVIEW_TILES_JSON", previewStatus?.previewTilesJsonUrl || "");
      this.status = previewStatus?.status;
    }
  }
};
</script>

<style lang="scss" scoped>
.right-top-ui {
  .towards-left {
    .stage-button {
      display: inline-block;
      padding: 12px !important;
    }
  }
}
</style>
