import { isFeature } from "@mapbox/mapbox-gl-draw/src/lib/common_selectors";
import vm from "@/main";
import CustomToast from "@/components/shared/CustomToast";

const DisableMode = {};

DisableMode.toDisplayFeatures = function (state, geojson, display) {
  display(geojson);
};

DisableMode.dragMove = function () {
  const selectedFeatures = this.getSelected();
  if (selectedFeatures.some((feat) => feat.properties.isDisabled)) return;
};

DisableMode.onTap = DisableMode.onClick = function (state, e) {
  if (isFeature(e)) {
    vm.$toast.error({
      component: CustomToast,
      props: { message: vm.$t("contents.disable-mode-click-error") }
    });
  }
};

export default DisableMode;
