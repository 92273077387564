<template>
  <div class="add-edit-inputs-container">
    <v-expansion-panels v-model="defaultOpenPanelIndex" class="content-add-edit-expansion-panels position-static" flat>
      <v-expansion-panel active-class="expanded" class="mt-0 position-static">
        <v-expansion-panel-header>
          <PtrIcon class="expansion-panel-header-icon" icon="information" />
          <div class="expansion-panel-header-text">{{ $t(`${translationPath}basic-information`) }}</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="content-add-edit-panel-title">
            <v-col md="10">{{ $t(`${translationPath}metadata`) }}</v-col>
          </v-row>
          <v-form v-model="isAutoCompleteValid" class="mt-2 mb-3">
            <v-row>
              <v-col class="py-1">
                <v-autocomplete
                  id="content-type-input"
                  v-model="selectedType"
                  :class="{ disabled: isEdit }"
                  :items="typeList"
                  item-text="title"
                  item-value="code"
                  outlined
                  dense
                  :label="$t(`${translationPath}types`)"
                  hide-details="auto"
                  :menu-props="{ closeOnContentClick: true }"
                  :rules="[autoCompleteRequired]"
                  :disabled="isEdit"
                  @keydown="setFormDirty"
                >
                  <template #item="{ item }">
                    <v-list-item @click="onTypeSelected(item.code)">
                      <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template #append>
                    <PtrIcon icon="caret-down" />
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
          <v-form ref="mdpoi" v-model="valid">
            <v-row>
              <v-col class="py-1">
                <v-text-field
                  id="name-input"
                  v-model.trim="name"
                  :rules="[rules.featureName]"
                  :label="$t(featureTranslationPath + namePath)"
                  hide-details="auto"
                  outlined
                  dense
                  @keydown="setFormDirty"
                />
              </v-col>
            </v-row>
            <PointGeometry
              ref="pointGeometryComponent"
              :feature="feature"
              marker-class="wayfinding-transition-node"
              :features="transitions"
              :selected-type="selectedType"
              @featureSelected="(feature) => onFeatureSelected(feature)"
            ></PointGeometry>
          </v-form>
          <div class="form-footer mt-4">
            {{ $t(`${translationPath}required`) }}
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel active-class="expanded" class="mt-0" :disabled="!isCoordinatesSet || !selectedType">
        <v-expansion-panel-header>
          <PtrIcon class="expansion-panel-header-icon" icon="network" />
          <div class="expansion-panel-header-text">{{ $t(`${translationPath}transition-links`) }}</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div v-if="!isCustomType">
            <div class="item-description">
              {{ $t(`${translationPath}transition-links-description`, { selectedType }) }}
            </div>
            <TransitionLinks
              ref="transitionLinkComponent"
              :current-node-object="currentTransitionNode"
              :edge-list="edgeList"
              :available-nodes="linkOptions"
              :type="selectedType"
              :current-portal-group-id="feature?.properties?.portalGroupId"
              :nodes-ids-to-add="nodesIdsToAdd"
              :portal-travel-time="portalTravelTime"
              :is-accessible="isAccessible"
              :is-comfortable="isComfortable"
              @addEdge="(payload) => onEdgeAdded(payload)"
              @mergeWithCurrentGroup="(payload) => mergeWithCurrentGroup(payload)"
              @edgeRemoved="(payload) => onEdgeRemoved(payload)"
              @directionUpdated="(payload) => onDirectionUpdated(payload)"
              @portalTravelTimeChanged="(newVal) => onPortalTravelTimeChanged(newVal)"
              @isAccessibleChanged="(newVal) => onIsAccessibleChanged(newVal)"
              @isComfortableChanged="(newVal) => onIsComfortableChanged(newVal)"
              @transitionPropertiesValid="(newVal) => validateTransitionProperties(newVal)"
              @addNewClicked="addNewTransitionLinkClicked"
            />
          </div>
          <div v-else>
            <CustomTransitionLinks
              ref="customTransitionLinkComponent"
              :current-node-object="currentTransitionNode"
              :current-portal-group-id="feature?.properties?.portalGroupId"
              :edge-list="edgeList"
              :nodes-ids-to-add="nodesIdsToAdd"
              :portal-travel-time="portalTravelTime"
              :is-accessible="isAccessible"
              :is-comfortable="isComfortable"
              @linkFeature="(payload) => onCustomFeatureLinked(payload)"
              @createLink="(payload) => onCustomLinkCreated(payload)"
              @mergeWithCurrentGroup="(payload) => mergeWithCustomGroup(payload)"
              @edgeRemoved="(payload) => onCustomEdgeRemoved(payload)"
              @directionUpdated="(payload) => onDirectionUpdated(payload)"
              @portalTravelTimeChanged="(newVal) => onPortalTravelTimeChanged(newVal)"
              @isAccessibleChanged="(newVal) => onIsAccessibleChanged(newVal)"
              @isComfortableChanged="(newVal) => onIsComfortableChanged(newVal)"
              @transitionPropertiesValid="(newVal) => validateTransitionProperties(newVal)"
              @addNewClicked="addNewTransitionLinkClicked"
              @createNewLinkClicked="addNewTransitionLinkClicked"
            />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel active-class="expanded" class="mt-0">
        <v-expansion-panel-header>
          <PtrIcon class="expansion-panel-header-icon" icon="custom-integration" />
          <div class="expansion-panel-header-text">{{ $t(`${translationPath}custom-integration`) }}</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <CustomIntegration
            ref="customIntegration"
            :extra-data-prop="extraData"
            :feature-id="featureId"
            @integrationUpdated="setFormDirty"
            @setFormDirty="setFormDirty"
          ></CustomIntegration>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <slot name="danger-zone"></slot>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapState } from "vuex";
import PtrIcon from "@/components/shared/PtrIcon.vue";
import TransitionLinks from "@/components/mapDesigner/TransitionLinks.vue";
import CustomIntegration from "@/components/shared/CustomIntegration.vue";
import CustomTransitionLinks from "@/components/mapDesigner/CustomTransitionLinks.vue";
import PointGeometry from "@/components/mapDesigner/PointGeometry.vue";
import ValidationHelpers from "@/helpers/ValidationHelpers";
import Helpers from "@/helpers/Helpers";

import ContentService from "@/services/ContentService";
import CustomToast from "@/components/shared/CustomToast.vue";
import TransitionTaxonomy from "@/constants/transitionTaxonomy";

const DISPLAY_MODE_OPTIONAL = "optional";

export default {
  components: { PtrIcon, TransitionLinks, CustomTransitionLinks, CustomIntegration, PointGeometry },
  data: () => ({
    translationPath: "contents.mapDesigner.",
    featureTranslationPath: "contents.feature.",
    feature: {},
    selectedType: undefined,
    featureNameDisplayMode: "",
    taxonomyType: "",
    name: "",
    extraData: {},
    portalTravelTime: "",
    valid: false,
    isAutoCompleteValid: false,
    isEdit: false,
    isAccessible: false,
    isComfortable: false,
    edgeList: [],
    cacheEdgeList: [],
    featureId: "",
    removedFeatures: [],
    removedFeaturesFromMergedGroups: [],
    mergedGroupIds: [],
    generatedFid: undefined,
    nodesIdsToAdd: [],
    defaultOpenPanelIndex: 0,
    isDrawingFinished: false,
    editGeometryClicked: false,
    drawMode: undefined,
    areTransitionPropertiesValid: true,

    isTransitionLinkAddActive: false,
    localBuilding: undefined,
    localLevel: undefined,
    geometryAction: undefined
  }),
  computed: {
    ...mapState("CONTENT", ["buildings", "graphs", "isFormDirty"]),
    ...mapState("MAP", [
      "map",
      "currentSite",
      "currentBuilding",
      "currentLevel",
      "drawnCoordinates",
      "isMapBorderEnabled",
      "clickedTransitionFeature",

      "guidanceMessage"
    ]),
    taxonomy() {
      return TransitionTaxonomy.TRANSITION_TYPES;
    },
    typeList() {
      let currentTransitionFeatureTypecodes = [];
      currentTransitionFeatureTypecodes = this.graphs
        .filter(
          (feature) =>
            feature.properties.lvl == this.currentLevel &&
            feature.properties.bid == this.currentBuilding &&
            feature.properties.typeCode !== "graph-node"
        )
        .map((feature) => feature.properties.typeCode);

      currentTransitionFeatureTypecodes = [...new Set(currentTransitionFeatureTypecodes)];

      const allTypes = Object.values(this.taxonomy);
      const allTransitionTypes = allTypes.filter(
        (typeObj) =>
          typeObj.properties.class === "graph" && typeObj.code !== "graph-node" && typeObj.code !== "graph-obstacle"
      );
      const suggestedTransitionTypes = allTransitionTypes.filter((typeObj) =>
        currentTransitionFeatureTypecodes.includes(typeObj.code)
      );
      let autoCompleteItems = [];
      if (suggestedTransitionTypes.length) {
        autoCompleteItems = [
          {
            header: "SUGGESTED TYPES"
          },
          ...suggestedTransitionTypes
        ];
      }
      return [
        ...autoCompleteItems,
        {
          header: "ALL TYPES"
        },
        ...allTransitionTypes
      ];
    },
    typeTitle() {
      return this.typeList?.find((type) => {
        return type?.code === this.selectedType;
      })?.title;
    },
    autoGeneratedName() {
      return (
        Object.values(this.taxonomy)
          .find((typeObj) => typeObj.code === this.selectedType)
          ?.title?.replace(" Node", "") || ""
      );
    },
    rules() {
      return {
        featureName: (value) => {
          return ValidationHelpers.isRequired(value?.trim());
        },
        required: (value) => ValidationHelpers.isRequired(value)
      };
    },
    shouldShowPointIcon() {
      return this.taxonomyType?.properties?.shape?.includes("point") ? true : false;
    },
    shouldShowPolygonIcon() {
      return this.taxonomyType?.properties?.shape?.includes("polygon") ? true : false;
    },
    editedFeature() {
      // if name is default value, this means we don't have a feature being edited
      // if (this.name === undefined) return undefined;
      return {
        name: this.name,
        portalTravelTime: Number(this.portalTravelTime) || 0,
        isAccessible: this.isAccessible,
        isComfortable: this.isComfortable
      };
    },
    namePath() {
      if (this.featureNameDisplayMode === DISPLAY_MODE_OPTIONAL) {
        return "name";
      }
      return "name-required";
    },
    linkOptions() {
      if (this.isDuplex && this.linkedNodeIds.length === 2) {
        return [];
      }
      let filteredFeatures = this.graphs.filter(
        (feature) => feature.properties.typeCode === this.selectedType && feature.properties.fid !== this.featureId
      );
      if (this.isDuplex) {
        filteredFeatures = filteredFeatures.filter((feature) => feature.properties.portalGroupId === undefined);
      }
      if (!this.isCustomType) {
        filteredFeatures = filteredFeatures.filter(
          (feature) => feature.properties.bid === this.localBuilding && feature.properties.lvl !== this.localLevel
        );
      }
      const allOptions = filteredFeatures.concat(this.removedFeatures).concat(this.removedFeaturesFromMergedGroups);
      const uniqueOptions = [];
      return allOptions.filter((option) => {
        const isDuplicate =
          uniqueOptions?.findIndex((unique) => unique.properties.fid === option.properties.fid) !== -1;
        if (!isDuplicate) {
          uniqueOptions.push(option);
          return true;
        }
        return false;
      });
    },
    linkedNodeIds() {
      let nodes = this.edgeList.map((edge) => edge.a);
      if (this.edgeList.length) {
        nodes.push(this.edgeList[this.edgeList.length - 1].b);
      }
      nodes = nodes.filter((node) => node !== undefined);
      return nodes;
    },
    isDuplex() {
      return this.taxonomyType?.properties?.portalFloor === "duplexNonAligned";
    },
    currentTransitionNode() {
      return {
        properties: {
          fid: this.featureId || this.generatedFid,
          bid: this.localBuilding,
          lvl: this.localLevel,
          name: this.name
        }
      };
    },
    edgeDirection() {
      const defaultDirection = Object.values(this.taxonomy).find((typeObj) => {
        return typeObj.code === this.selectedType;
      })?.properties?.portalDirection;
      if (defaultDirection === "unidirectional") {
        return "a-to-b";
      }
      return "bi";
    },
    isCoordinatesSet() {
      return this.drawnCoordinates && this.drawnCoordinates?.length !== 0;
    },
    isLinked() {
      return !!this.edgeList[0].b;
    },
    isCustomType() {
      return TransitionTaxonomy.NON_CUSTOM_TYPES.indexOf(this.selectedType) === -1;
    },
    isAllValid() {
      return (
        this.valid &&
        this.isAutoCompleteValid &&
        this.drawnCoordinates &&
        this.drawnCoordinates?.length !== 0 &&
        this.areTransitionPropertiesValid &&
        !this.isMapBorderEnabled
      );
    },
    transitions() {
      return this.graphs?.filter((feature) => {
        return (
          feature?.properties?.typeCode !== "graph-node" &&
          feature?.properties?.bid == this.currentBuilding &&
          feature?.properties?.lvl == this.currentLevel
        );
      });
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.$store.commit("MAP/DRAWN_COORDINATES", undefined);
        this.featureId = this.$route.query?.fid;
        this.feature = this.graphs.find((feature) => feature.properties.fid === this.featureId);
        this.localLevel = this.currentLevel;
        this.localBuilding = this.currentBuilding;
        if (this.feature) {
          this.isEdit = true;
          this.name = this.feature.properties.name;
          const typeCode = this.feature.properties.typeCode;
          this.selectedType =
            TransitionTaxonomy.NON_CUSTOM_TYPES.indexOf(typeCode) !== -1 ? typeCode : "custom-transition";
          this.extraData = this.feature.properties?.extra || this.feature.properties?.extraData;
          this.portalTravelTime = Number(this.feature.properties.travelTime) || 0;
          this.isAccessible = this.feature.properties.isAccessible;
          this.isComfortable = this.feature.properties.isComfortable;
          this.taxonomyType = this.taxonomy[this.selectedType];
          if (this.feature.properties.portalNeighbors === undefined) {
            this.feature.properties.portalNeighbors = [];
          }
          if (this.feature.properties.neighbors === undefined) {
            this.feature.properties.neighbors = [];
          }
          this.updateEdgeList();
          this.$store.commit("MAP/DRAWN_COORDINATES", this.drawnCoordinates || this.feature.geometry.coordinates);
          this.isDrawingFinished = true;
        } else if (this.featureId !== undefined) {
          this.isEdit = false;
          this.selectedType = undefined;
          this.name = undefined;
          this.portalTravelTime = "";
          this.isAccessible = false;
          this.isComfortable = false;
          this.taxonomyType = "";
        } else {
          this.generatedFid = Helpers.generateUuid();
        }
      }
    },
    transitions() {
      this.$refs.pointGeometryComponent?.generateMarkers();
    },
    valid() {
      this.$emit("valid", this.valid && this.isAutoCompleteValid && this.isCoordinatesSet);
    },
    isAutoCompleteValid() {
      this.$emit("valid", this.isAllValid);
    },
    drawnCoordinates() {
      this.$emit("valid", this.isAllValid);
    },
    areTransitionPropertiesValid() {
      this.$emit("valid", this.isAllValid);
    },
    isMapBorderEnabled() {
      this.$emit("valid", this.isAllValid);
    },
    editedFeature: {
      handler(newVal, oldVal) {
        if (!oldVal || !newVal || !Object.keys(oldVal).length) {
          return;
        }
        let isDirty = false;
        if (newVal.name !== this.feature?.properties?.name) {
          isDirty = true;
        }
        if (newVal.portalTravelTime !== this.feature?.properties?.travelTime) {
          isDirty = true;
        }
        if (newVal.isAccessible !== this.feature?.properties?.isAccessible) {
          isDirty = true;
        }
        if (newVal.isComfortable !== this.feature?.properties?.isComfortable) {
          isDirty = true;
        }
        this.$store.commit("CONTENT/IS_FORM_DIRTY", isDirty);
      },
      deep: true
    },
    name() {
      if (!this.isEdit) {
        this.$store.commit("CONTENT/IS_FORM_DIRTY", this.name !== "");
      }
    },
    clickedTransitionFeature() {
      if (
        this.clickedTransitionFeature === undefined ||
        !this.isTransitionLinkAddActive ||
        this.clickedTransitionFeature.properties.typeCode !== this.selectedType
      ) {
        return;
      }
      const fid = this.clickedTransitionFeature.properties.fid;
      const unLinkedFeatureId = this.edgeList.find((edge) => edge.b === undefined)?.a;
      if (
        this.edgeList.find(
          (edge) => (edge.a === fid && edge.b === unLinkedFeatureId) || (edge.b === fid && edge.a === unLinkedFeatureId)
        )
      ) {
        return;
      }
      if (this.isCustomType) {
        this.$refs.customTransitionLinkComponent.linkFeature(this.clickedTransitionFeature);
      } else {
        this.$refs.transitionLinkComponent.add(this.clickedTransitionFeature);
      }
    }
  },
  created() {
    this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
    this.parseTypeForInputs();
  },
  async beforeDestroy() {
    this.$store.commit("MAP/DRAWN_COORDINATES", undefined);
    this.$store.commit("MAP/IS_MAP_BORDER_ENABLED", false);
    this.$store.commit("MAP/GUIDANCE_MESSAGE", undefined);
  },
  methods: {
    onTypeSelected(newType) {
      if (newType !== this.selectedType) {
        this.selectedType = newType;
        this.taxonomyType = this.taxonomy[this.selectedType];
        this.parseTypeForInputs();
        this.name = this.autoGeneratedName;
        this.portalTravelTime = Number(this.taxonomyType?.properties?.portalTravelTime) || 0;
        this.isAccessible = this.taxonomyType?.properties?.isPortalAccessible === "true";
        this.isComfortable = this.taxonomyType?.properties?.isPortalComfortable === "true";
        this.featureNameDisplayMode = this.taxonomyType?.properties?.isTitleEnabled;
        this.resetEdgeList();
        if (this.guidanceMessage) {
          this.$store.commit(
            "MAP/GUIDANCE_MESSAGE",
            this.$t("contents.guidance.click-map-transition", { type: this.typeTitle })
          );
        }
        if (this.isEdit && this.selectedType && this.feature.properties.typeCode !== this.selectedType) {
          this.$store.commit("CONTENT/IS_FORM_DIRTY", true);
        } else if (this.isEdit && this.feature.properties.typeCode === this.selectedType) {
          this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
        }
      }
    },
    //TODO: Move to a helper
    autoCompleteRequired(value) {
      if (value === null || value === undefined) {
        this.isAutoCompleteValid = false;
        // TODO: Move to language file
        return this.$t("contents.validations.type-must-be-selected");
      }
      this.isAutoCompleteValid = true;
      return true;
    },

    async save() {
      let tempGraphs = [...this.graphs];
      let currentPortalGroupId;
      let coordinates;
      try {
        coordinates = JSON.parse(this.drawnCoordinates || "[]");
      } catch (e) {
        coordinates = this.drawnCoordinates;
      }
      let extraData = this.$refs?.customIntegration?.getExtraData();
      if (!extraData && this.feature) {
        extraData = this.feature?.properties?.extra || this.feature?.properties?.extraData;
      }
      if (this.isEdit) {
        const idx = tempGraphs?.findIndex((feature) => feature.properties.fid === this.featureId);
        tempGraphs[idx].geometry = {
          type: "Point",
          coordinates: coordinates
        };
        tempGraphs[idx].properties.name = this.name?.trim();
        tempGraphs[idx].properties.typeCode = this.selectedType;
        tempGraphs[idx].properties.travelTime = Number(this.portalTravelTime) || 0;
        tempGraphs[idx].properties.isAccessible = this.isAccessible;
        tempGraphs[idx].properties.isComfortable = this.isComfortable;
        tempGraphs[idx].properties.extra = extraData;
        tempGraphs[idx].lvl = Number(this.currentLevel);
        tempGraphs[idx].bid = Number(this.currentBuilding);
        tempGraphs[idx].sid = Number(this.currentSite);
        if (this.isLinked) {
          tempGraphs[idx].properties.portalGroupId = this.feature.properties.portalGroupId || Helpers.generateUuid();
        }
        currentPortalGroupId = tempGraphs[idx].properties.portalGroupId;
      } else {
        const transitionNode = {
          type: "Feature",
          properties: {
            bid: Number(this.currentBuilding),
            lvl: Number(this.currentLevel),
            neighbors: this.neighbors || [],
            portalNeighbors: this.portalNeighbors || [],
            sid: Number(this.currentSite),
            fid: this.generatedFid,
            typeCode: this.selectedType,
            name: this.name?.trim(),
            isAccessible: this.isAccessible,
            isComfortable: this.isComfortable,
            extra: extraData,
            travelTime: Number(this.portalTravelTime) || 0
          },
          geometry: {
            type: "Point",
            coordinates: coordinates
          }
        };
        if (this.isLinked) {
          transitionNode.properties.portalGroupId = Helpers.generateUuid();
          currentPortalGroupId = transitionNode.properties.portalGroupId;
        }
        tempGraphs.push(transitionNode);
      }
      if (this.isCustomType) {
        tempGraphs = this.resetPortalInfoForCustomTransitions(tempGraphs);
      }
      tempGraphs = this.editPortalNeighbors(tempGraphs, currentPortalGroupId);
      tempGraphs = this.handleRemovedFeaturesFromMergedGroups(tempGraphs);

      let response = await ContentService.postGraphBySite(this.currentSite, {
        features: tempGraphs,
        type: "FeatureCollection"
      });
      if (response?.createdTimestampUtcEpochSeconds) {
        this.$store.commit("CONTENT/LOCAL_GRAPHS", tempGraphs);
        this.$toast.success({
          component: CustomToast,
          props: {
            message: this.$t(`${this.translationPath}graph-update-success`)
          }
        });
        this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
        return true;
      } else {
        this.$toast.error({
          component: CustomToast,
          props: { message: response?.message || this.$t(`${this.translationPath}graph-update-fail`) }
        });
        return false;
      }
    },
    async deleteContent() {
      let tempGraphs = [...this.graphs];
      const fidOfFeatureToDelete = this.featureId || this.generatedFid;
      const indexToRemove = tempGraphs?.findIndex((feature) => feature.properties.fid === fidOfFeatureToDelete);
      tempGraphs.splice(indexToRemove, 1);
      tempGraphs.forEach((feature) => {
        const index = feature.properties.portalNeighbors?.findIndex(
          (neighbor) => neighbor.fid === fidOfFeatureToDelete
        );
        if (index !== -1) {
          feature.properties.portalNeighbors?.splice(index, 1);
        }
      });
      this.$store.commit("CONTENT/LOCAL_GRAPHS", tempGraphs);

      let response = await ContentService.postGraphBySite(this.currentSite, {
        features: tempGraphs,
        type: "FeatureCollection"
      });
      if (response?.createdTimestampUtcEpochSeconds) {
        this.$store.commit("CONTENT/LOCAL_GRAPHS", tempGraphs);
        this.$toast.success({
          component: CustomToast,
          props: {
            message: this.$t(`${this.translationPath}graph-update-success`)
          }
        });
        this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
        return true;
      } else {
        this.$toast.error({
          component: CustomToast,
          props: { message: response?.message || this.$t(`${this.translationPath}graph-update-fail`) }
        });
        return false;
      }
    },
    editPortalNeighbors(graphFeatures, currentPortalGroupId) {
      let temp = [...graphFeatures];
      if (this.isLinked) {
        this.resetPortalNeighbors(graphFeatures);
        const isLift = this.selectedType === "lift-node" || this.selectedType === "elevator-node";
        if (isLift) {
          this.handlePortalsForLifts(graphFeatures, currentPortalGroupId);
        } else {
          this.edgeList.forEach((edge) => {
            const { a, b, direction } = edge;
            const aIndex = graphFeatures?.findIndex((feature) => feature.properties.fid === a);
            const bIndex = graphFeatures?.findIndex((feature) => feature.properties.fid === b);

            switch (direction) {
              case "bi":
                if (temp[aIndex].properties.portalNeighbors?.findIndex((neighbor) => neighbor.fid === b) === -1) {
                  temp[aIndex].properties.portalNeighbors.push({ fid: b });
                }
                if (temp[bIndex].properties.portalNeighbors?.findIndex((neighbor) => neighbor.fid === a) === -1) {
                  temp[bIndex].properties.portalNeighbors.push({ fid: a });
                }
                break;
              case "b-to-a":
                if (temp[bIndex].properties.portalNeighbors?.findIndex((neighbor) => neighbor.fid === a) === -1) {
                  temp[bIndex].properties.portalNeighbors.push({ fid: a });
                }
                temp[aIndex].properties.portalNeighbors = temp[aIndex].properties.portalNeighbors.filter(
                  (neighbor) => neighbor.fid !== b
                );
                break;
              default:
                if (temp[aIndex].properties.portalNeighbors?.findIndex((neighbor) => neighbor.fid === b) === -1) {
                  temp[aIndex].properties.portalNeighbors.push({ fid: b });
                }
                temp[bIndex].properties.portalNeighbors = temp[bIndex].properties.portalNeighbors.filter(
                  (neighbor) => neighbor.fid !== a
                );
            }
            temp[aIndex].properties.portalGroupId = currentPortalGroupId;
            temp[bIndex].properties.portalGroupId = currentPortalGroupId;
            temp[aIndex].properties.isAccessible = this.isAccessible;
            temp[bIndex].properties.isAccessible = this.isAccessible;
            temp[aIndex].properties.isComfortable = this.isComfortable;
            temp[bIndex].properties.isComfortable = this.isComfortable;
            temp[aIndex].properties.travelTime = Number(this.portalTravelTime) || 0;
            temp[bIndex].properties.travelTime = Number(this.portalTravelTime) || 0;
            temp[aIndex].properties.typeCode = this.selectedType;
            temp[bIndex].properties.typeCode = this.selectedType;
          });
        }
      }
      temp = this.handleFeatureRemove(temp, currentPortalGroupId);
      return temp;
    },
    resetPortalInfoForCustomTransitions(tempGraphs) {
      this.cacheEdgeList.forEach((edge) => {
        const edgeA = edge.a;
        const edgeB = edge.b;
        if (edgeA) {
          const aIndex = tempGraphs?.findIndex((feature) => feature.properties.fid === edgeA);
          delete tempGraphs[aIndex].properties.portalGroupId;
          tempGraphs[aIndex].properties.portalNeighbors = [];
        }
        if (edgeB) {
          const bIndex = tempGraphs?.findIndex((feature) => feature.properties.fid === edgeB);
          delete tempGraphs[bIndex].properties.portalGroupId;
          tempGraphs[bIndex].properties.portalNeighbors = [];
        }
      });
      return tempGraphs;
    },
    handlePortalsForLifts(graphFeatures, currentPortalGroupId) {
      this.linkedNodeIds.forEach((nodeId) => {
        const index = graphFeatures?.findIndex((feature) => feature.properties.fid === nodeId);
        const portalNeighbors = this.linkedNodeIds
          .filter((id) => id !== nodeId)
          .map((id) => {
            return {
              fid: id
            };
          });
        graphFeatures[index].properties.portalNeighbors = portalNeighbors;
        graphFeatures[index].properties.portalGroupId = currentPortalGroupId;
        graphFeatures[index].properties.travelTime = Number(this.portalTravelTime) || 0;
        graphFeatures[index].properties.isAccessible = this.isAccessible;
        graphFeatures[index].properties.isComfortable = this.isComfortable;
      });
    },
    resetPortalNeighbors(graphFeatures) {
      this.linkedNodeIds.forEach((id) => {
        const index = graphFeatures?.findIndex((feature) => feature.properties.fid === id);
        graphFeatures[index].properties.portalNeighbors = [];
      });
    },
    handleFeatureRemove(graphNodes, currentPortalGroupId) {
      this.removedFeatures.forEach((feature) => {
        const removedFeatureId = feature.properties.fid;
        const index = graphNodes?.findIndex((node) => node.properties.fid === removedFeatureId);
        //TODO: reset other group configuartions: traveltime, isAccessible, isComfortable
        delete graphNodes[index].properties.portalGroupId;
        const portalNeighbors = graphNodes.filter(
          (node) => node.properties.portalNeighbors?.findIndex((neighbor) => neighbor.fid === removedFeatureId) !== -1
        );
        portalNeighbors.forEach((neighbor) => {
          const idx = neighbor.properties.portalNeighbors?.findIndex(
            (innerNeighbor) => innerNeighbor.fid === feature.properties.fid
          );
          neighbor.properties.portalNeighbors?.splice(idx, 1);
        });
        graphNodes[index].properties.portalNeighbors = [];
      });
      if (graphNodes.filter((feature) => feature.properties.portalGroupId === currentPortalGroupId).length === 1) {
        const index = graphNodes?.findIndex((feature) => feature.properties.portalGroupId === currentPortalGroupId);
        delete graphNodes[index].properties.portalGroupId;
        //TODO: reset other group configuartions: traveltime, isAccessible, isComfortable
      }
      return graphNodes;
    },
    handleRemovedFeaturesFromMergedGroups(graphNodes) {
      this.removedFeaturesFromMergedGroups.forEach((feature) => {
        const fid = feature.properties.fid;
        const index = graphNodes?.findIndex((node) => node.properties.fid === fid);
        delete graphNodes[index].properties.portalGroupId;
        graphNodes[index].properties.portalNeighbors = [];
        //TODO: reset other group configuartions: traveltime, isAccessible, isComfortable
        const featuresThatHasRemovedFeatureAsPortalNeighbor = graphNodes.filter(
          (node) => node.properties.portalNeighbors?.findIndex((neighbor) => neighbor.fid === fid) !== -1
        );
        featuresThatHasRemovedFeatureAsPortalNeighbor.forEach((innerFeature) => {
          const neighborIndexToRemove = innerFeature.properties.portalNeighbors?.findIndex(
            (neighbor) => neighbor.fid === fid
          );
          const graphIndexToUpdate = graphNodes?.findIndex(
            (node) => node.properties.fid === innerFeature.properties.fid
          );
          graphNodes[graphIndexToUpdate].properties.portalNeighbors.splice(neighborIndexToRemove, 1);
        });
      });
      return graphNodes;
    },

    parseTypeForInputs() {
      this.featureNameDisplayMode = this.taxonomyType?.properties?.isTitleEnabled;
    },
    async onEdgeAdded(payload) {
      this.setFormDirty();
      await this.hideBuildingLevelSelector();

      const { feature, index } = payload;
      const idsInCurrentEdgeList = this.edgeList.map((edge) => edge.a);
      if (this.edgeList.length > 1) {
        idsInCurrentEdgeList.push(this.edgeList[this.edgeList.length - 1].b);
      }
      if (idsInCurrentEdgeList?.findIndex((id) => id === feature.properties.fid) !== -1) {
        return;
      }
      let newEdgeObject = { direction: this.edgeDirection };
      let tempList = [...this.edgeList];
      if (index === -1) {
        newEdgeObject.a = feature.properties.fid;
        newEdgeObject.b = this.edgeList[0].a;
        tempList.unshift(newEdgeObject);
      } else if (index === tempList.length) {
        newEdgeObject.a = tempList[index - 1].b;
        newEdgeObject.b = feature.properties.fid;
        tempList.push(newEdgeObject);
      } else {
        newEdgeObject.a = tempList[index].a;
        newEdgeObject.b = feature.properties.fid;
        if (!this.isLinked) {
          tempList[0] = newEdgeObject;
        } else {
          tempList.splice(index, 0, newEdgeObject);
          tempList[index + 1].a = feature.properties.fid;
        }
      }
      const idx = this.nodesIdsToAdd?.findIndex((id) => id === feature.properties.fid);
      if (idx !== -1) {
        this.nodesIdsToAdd.splice(idx, 1);
      }
      tempList = tempList.filter((edgeObj) => edgeObj.b !== undefined);
      this.edgeList = [...tempList];
      let removedIndex = this.removedFeatures?.findIndex(
        (removedFeature) => removedFeature.properties.fid === feature.properties.fid
      );
      if (removedIndex !== -1) {
        this.removedFeatures.splice(removedIndex, 1);
      }
      removedIndex = this.removedFeaturesFromMergedGroups?.findIndex(
        (removedFeature) => removedFeature.properties.fid === feature.properties.fid
      );
      if (removedIndex !== -1) {
        this.removedFeaturesFromMergedGroups.splice(removedIndex, 1);
      }
    },
    async onCustomFeatureLinked(payload) {
      this.setFormDirty();
      await this.hideBuildingLevelSelector();
      let temp = [...this.edgeList];
      const { feature, index } = payload;
      if (index === -1) {
        temp.push({
          a: feature.properties.fid,
          b: undefined,
          direction: "a-to-b"
        });
        this.edgeList = temp;
      } else {
        temp[index].b = feature.properties.fid;
        this.edgeList = temp;
      }
    },
    async onCustomLinkCreated(payload) {
      this.setFormDirty();
      await this.hideBuildingLevelSelector();

      const { feature } = payload;
      let temp = [...this.edgeList];
      temp.push({ a: feature.properties.fid, direction: this.edgeDirection });
      this.edgeList = temp;
    },
    mergeWithCurrentGroup(feature) {
      const edgeListToBeMerged = this.getEdgeListFromFid(feature.properties.fid);
      const nodesOfEdgeList = edgeListToBeMerged.map((edge) => edge.a);
      nodesOfEdgeList.push(edgeListToBeMerged[edgeListToBeMerged.length - 1].b);
      this.nodesIdsToAdd = nodesOfEdgeList;
      const groupIdToBeMerged = feature.properties.portalGroupId;
      const index = this.mergedGroupIds?.findIndex((id) => id === groupIdToBeMerged);
      if (index === -1) {
        this.mergedGroupIds.push(groupIdToBeMerged);
      }
    },
    mergeWithCustomGroup(payload) {
      const { feature, index } = payload;
      const edgeListToBeMerged = this.getEdgeListFromFid(feature.properties.fid);
      this.onCustomFeatureLinked({ feature, index });
      this.edgeList = this.edgeList.concat(edgeListToBeMerged);
    },
    onEdgeRemoved(payload) {
      this.setFormDirty();
      let tempList = [...this.edgeList];
      const { index, feature } = payload;
      if (index === -1) {
        tempList.shift();
        if (this.edgeList.length === 1) {
          tempList.push({ a: this.currentTransitionNode.properties.fid, direction: this.edgeDirection });
        }
      } else if (index === this.edgeList.length - 1) {
        if (this.edgeList.length === 1) {
          delete tempList[0].b;
        } else {
          tempList.pop();
        }
      } else {
        const edgeToDelete = tempList[index];
        if (index === 0) {
          tempList[1].a = edgeToDelete.a;
        } else {
          tempList[index + 1].a = tempList[index - 1].b;
        }
        tempList.splice(index, 1);
      }
      this.edgeList = [...tempList];
      const removedFeatureGroupId = feature.properties.portalGroupId;
      if (this.feature?.properties?.portalGroupId === removedFeatureGroupId) {
        this.removedFeatures.push(feature);
      } else if (this.mergedGroupIds.find((id) => id === removedFeatureGroupId)) {
        this.removedFeaturesFromMergedGroups.push(feature);
      }
    },
    onCustomEdgeRemoved(payload) {
      this.setFormDirty();
      const { index } = payload;
      if (this.edgeList.length === 1) {
        this.edgeList[0].a = this.currentTransitionNode.properties.fid;
        this.edgeList[0].b = undefined;
        this.edgeList[0].direction = "a-to-b";
        this.edgeList = [...this.edgeList];
        return;
      }
      let temp = [...this.edgeList];
      temp.splice(index, 1);

      const duplicate = temp.find(
        (edge, tempIndex) => tempIndex !== index && temp[index]?.a === edge.a && temp[index]?.b === edge.b
      );
      if (duplicate) {
        temp.splice(index, 1);
      }
      this.edgeList = temp;
    },
    onDirectionUpdated(payload) {
      const { index, newDirection } = payload;
      let tempList = [...this.edgeList];
      tempList[index].direction = newDirection;
      this.edgeList = [...tempList];
    },
    resetEdgeList() {
      this.edgeList = [{ a: this.currentTransitionNode.properties.fid, direction: this.edgeDirection }];
    },
    updateEdgeList() {
      this.edgeList = [...this.getEdgeListFromFid(this.featureId)];
    },
    getEdgeListFromFid(fid) {
      if (this.selectedType === "lift-node") {
        return this.getElevatorEdgeListFromFid(fid);
      }
      const alreadyVisitedNodeIds = new Set();
      const currentFeature = this.getFeatureFromFid(fid);
      const currentPortalGroupId = currentFeature.properties.portalGroupId;
      let traversalQueue;

      if (currentPortalGroupId) {
        traversalQueue = this.graphs
          .filter((feature) => feature.properties.portalGroupId === currentPortalGroupId)
          .sort((a, b) => a.properties.lvl - b.properties.lvl)
          .map((feature) => feature.properties.fid);
      } else {
        traversalQueue = [this.featureId];
      }
      const edgeList = [];
      while (traversalQueue.length !== 0) {
        let nodeId = traversalQueue.shift();
        if (alreadyVisitedNodeIds.has(nodeId)) {
          continue;
        }
        let nodeBeingVisited = this.getFeatureFromFid(nodeId);
        if (nodeBeingVisited === undefined) {
          continue;
        }
        alreadyVisitedNodeIds.add(nodeBeingVisited.properties.fid);
        nodeBeingVisited.properties.portalNeighbors.forEach((neighborId) => {
          const neighbor = this.getFeatureFromFid(neighborId?.fid);
          if (neighbor === undefined) {
            return;
          }
          let direction;
          let newEdge;
          if (nodeBeingVisited.properties.lvl <= neighbor.properties.lvl || this.isCustomType) {
            direction = "a-to-b";
            newEdge = { a: nodeBeingVisited.properties.fid, b: neighbor.properties.fid, direction };
          } else {
            direction = "b-to-a";
            newEdge = { a: neighbor.properties.fid, b: nodeBeingVisited.properties.fid, direction };
          }
          const index = edgeList?.findIndex(
            (edge) =>
              (edge.a === newEdge.a && edge.b === newEdge.b) ||
              (this.isCustomType && edge.a === newEdge.b && edge.b === newEdge.a)
          );
          if (index !== -1) {
            edgeList[index].direction = "bi";
            return;
          }
          edgeList.push(newEdge);
        });

        traversalQueue = traversalQueue.concat(
          nodeBeingVisited.properties.portalNeighbors
            .filter(
              (neighborId) =>
                !alreadyVisitedNodeIds.has(neighborId?.fid) &&
                traversalQueue?.findIndex((id) => id === neighborId?.fid) === -1
            )
            .map((neighbourObj) => neighbourObj.fid)
        );
      }
      // Handle initial empty edge list case
      if (edgeList.length === 0) {
        edgeList.push({ a: this.featureId, direction: this.edgeDirection });
      }
      this.cacheEdgeList = edgeList.map((edge) => {
        return { a: edge.a, b: edge.b, direction: edge.direction };
      });
      return edgeList;
    },

    getElevatorEdgeListFromFid(fid) {
      const currentFeature = this.getFeatureFromFid(fid);
      const currentPortalGroupId = currentFeature.properties.portalGroupId;
      let traversalQueue;

      if (currentPortalGroupId) {
        traversalQueue = this.graphs
          .filter((feature) => feature.properties.portalGroupId === currentPortalGroupId)
          .sort((a, b) => a.properties.lvl - b.properties.lvl)
          .map((feature) => feature.properties.fid);
      } else {
        traversalQueue = [this.featureId];
      }
      const edgeList = [];
      traversalQueue.forEach((featureId, index) => {
        const succeedingFeatureId = index === traversalQueue.length - 1 ? undefined : traversalQueue[index + 1];
        if (succeedingFeatureId) {
          edgeList.push({ a: featureId, b: succeedingFeatureId, direction: "bi" });
        }
      });

      if (edgeList.length === 0) {
        edgeList.push({ a: this.featureId, direction: this.edgeDirection });
      }
      return edgeList;
    },
    getFeatureFromFid(fid) {
      return this.graphs.find((feature) => feature.properties.fid === fid);
    },

    onPortalTravelTimeChanged(newPortalTravelTime) {
      this.portalTravelTime = newPortalTravelTime;
    },
    onIsAccessibleChanged(isAccessible) {
      this.isAccessible = isAccessible;
    },
    onIsComfortableChanged(isComfortable) {
      this.isComfortable = isComfortable;
    },
    validateTransitionProperties(isValid) {
      this.areTransitionPropertiesValid = isValid;
    },
    setFormDirty() {
      this.$store.commit("CONTENT/IS_FORM_DIRTY", true);
    },
    addNewTransitionLinkClicked() {
      this.isTransitionLinkAddActive = true;
    },
    async hideBuildingLevelSelector() {
      this.$store.commit("MAP/HIDE_UI_ICONS", "buildingLevelSelector");
      this.$store.commit("MAP/IS_MAP_BORDER_ENABLED", false);
      this.isTransitionLinkAddActive = false;
      const buildingId = parseInt(this.$route.params.buildingId, 10);
      const levelId = parseInt(this.$route.params.levelId, 10);
      if (this.currentBuilding !== buildingId) {
        this.$store.dispatch("MAP/SET_CURRENT_BUILDING", { building: buildingId });
      }
      if (this.currentLevel !== levelId) {
        await this.$store.dispatch("MAP/SET_CURRENT_LEVEL", { level: levelId });
      }
    },
    onFeatureSelected(selectedFeature) {
      this.$store.commit("MAP/CLICKED_TRANSITION_FEATURE", selectedFeature);
    }
  }
};
</script>
<style lang="scss" scoped>
$header-height: 56px;
$footer-buttons-height: 72px;
$padding: 24px;
.content-add-edit-panel-title {
  font-size: 13px;
  font-weight: 700;
  color: var(--v-neutral-darken1);
}

.add-edit-inputs-container {
  max-height: calc(100% - (#{$header-height} + #{$footer-buttons-height} + #{$padding}));
  overflow-x: hidden;
  overflow-y: overlay;
}

.item-description {
  color: var(--v-neutral-darken1);
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
}

.position-static {
  position: static !important;
}
</style>
