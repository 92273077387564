<template>
  <div>
    <div v-if="shouldDisplayToggle" id="floor-plan-container" class="d-flex align-center justify-space-between">
      <v-switch
        v-model="shouldShowOriginalFloorPlan"
        class="mt-0 pt-0"
        :class="{ 'primary-label': shouldShowOriginalFloorPlan }"
        :label="$t(`${translationPath}label`)"
        :color="shouldShowOriginalFloorPlan ? 'primary' : 'neutral'"
        hide-details
        inset
      ></v-switch>
      <BetaTag></BetaTag>
      <HelpComponent icon-height="14">
        <template #html-content>
          <p class="mb-0">{{ $t(`${translationPath}help`) }}</p>
        </template>
      </HelpComponent>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import HelpComponent from "@/components/shared/HelpComponent.vue";
import LevelStatusHelper from "@/helpers/LevelStatusHelper";
import BetaTag from "@/components/shared/BetaTag.vue";

const FORBIDDEN_ROUTE_NAMES = ["Sites", "Buildings", "Levels"];
const ALLOWED_FILE_EXTENSIONS = ["pdf", "dxf", "dwg"];

export default {
  name: "ShowFloorPlanToggle",
  components: { HelpComponent, BetaTag },
  data: () => ({
    translationPath: "map-settings.floor-plan.",
    shouldShowOriginalFloorPlan: false
  }),
  computed: {
    ...mapState("MAP", ["shouldShowMapIcons", "currentLevel", "shouldDisplayOriginalFloorPlan"]),
    ...mapState("CONTENT", ["levelStatuses", "originalMapObjects", "levels", "mapObjects"]),
    fileUrl() {
      return this.levelStatuses?.[this.currentLevel]?.floorPlanUrl;
    },
    fileExtension() {
      return this.levelStatuses?.[this.currentLevel]?.fileType;
    },
    shouldHide() {
      return FORBIDDEN_ROUTE_NAMES.findIndex((name) => this.$route.name === name) !== -1;
    },
    isFileTypeAllowed() {
      return ALLOWED_FILE_EXTENSIONS.findIndex((extension) => extension === this.fileExtension) !== -1;
    },
    shouldDisplayToggle() {
      return (
        !this.shouldHide &&
        this.isFileTypeAllowed &&
        LevelStatusHelper.isCompleted(this.levelStatuses[this.currentLevel]?.levelStatus)
      );
    },
    ...mapGetters("MAP", ["map", "currentClientObject", "currentLevelObject"])
  },
  watch: {
    async shouldShowOriginalFloorPlan() {
      if (this.shouldShowOriginalFloorPlan) {
        if (this.originalMapObjects.length === 0) {
          await this.setOriginalFloorPlan();
        }
        this.$store.commit("MAP/SHOULD_DISPLAY_ORIGINAL_FLOOR_PLAN", true);
        this.$store.dispatch("MAP/SHOW_ORIGINAL_FLOOR_PLAN");
      } else {
        this.$store.commit("MAP/SHOULD_DISPLAY_ORIGINAL_FLOOR_PLAN", false);
        this.$store.dispatch("MAP/ADD_MAP_OBJECTS_TO_MAP");
      }
    },
    currentLevel() {
      this.shouldShowOriginalFloorPlan = false;
      this.reset();
    },
    shouldDisplayToggle() {
      this.shouldShowOriginalFloorPlan = false;
    },
    shouldHide() {
      if (this.shouldHide) {
        this.reset();
      }
    }
  },
  methods: {
    blobToBase64(blob) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    },
    async setOriginalFloorPlan() {
      const anchors = this.mapObjects?.filter((feature) => feature?.properties?.typeCode === "georeferencing-anchor");
      const request = {
        fileType: this.fileExtension,
        quality: "low",
        georeferencing: {
          anchors: [
            {
              originalCoordinates: anchors?.[0]?.properties?.originalCoordinates,
              coordinates: anchors?.[0]?.geometry?.coordinates
            },
            {
              originalCoordinates: anchors[1].properties.originalCoordinates,
              coordinates: anchors[1].geometry.coordinates
            }
          ]
        },
        floorPlanFileUrl: this.fileUrl
      };
      await this.$store.dispatch("CONTENT/SET_ORIGINAL_MAP_OBJECTS", {
        request
      });
    },
    reset() {
      this.$store.commit("MAP/SHOULD_DISPLAY_ORIGINAL_FLOOR_PLAN", false);
      this.$store.commit("CONTENT/ORIGINAL_MAP_OBJECTS", []);
    }
  }
};
</script>
<style lang="scss" scoped>
#floor-plan-container {
  background-color: white;
  min-width: 168px;
  height: 40px;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px;

  ::v-deep {
    .v-label {
      line-height: 12px;
      min-width: 74px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .primary-label {
      .v-label {
        color: var(--v-primary-base) !important;
      }
    }
  }
}
</style>
