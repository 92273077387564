<template>
  <div class="poi-property-input-container pa-2">
    <v-row class="property-header mt-0 mb-1">Opening Hours</v-row>
    <v-row class="property-header mb-1" no-gutters>
      <v-col cols="12" sm="2">
        <div>Days</div>
      </v-col>
      <v-col cols="12" sm="5">
        <div>Opening</div>
      </v-col>
      <v-col cols="12" sm="5">
        <div>Closing</div>
      </v-col>
    </v-row>
    <OpeningHoursRow
      v-for="day in days"
      :key="day"
      :day="day"
      :value="openHours[day]?.[0]"
      @hoursUpdated="(newVal) => updateHours(newVal, day)"
      @isClosedSwitched="(newVal) => updateDay(newVal, day)"
    />
  </div>
</template>

<script>
import OpeningHoursRow from "@/components/mapDesigner/OpeningHoursRow.vue";
export default {
  components: { OpeningHoursRow },
  props: {
    value: Object
  },
  data: () => ({
    translationPath: "contents.feature.",
    openHours: undefined,
    days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
  }),
  watch: {
    openHours: {
      deep: true,
      handler() {
        this.$emit("input", this.openHours);
      }
    },
    value() {
      this.openHours = this.value;
    }
  },
  created() {
    this.openHours = this.value || {
      Monday: [["08:00", "22:00"]],
      Tuesday: [["08:00", "22:00"]],
      Wednesday: [["08:00", "22:00"]],
      Thursday: [["08:00", "22:00"]],
      Friday: [["08:00", "22:00"]]
    };
  },
  methods: {
    updateDay(isClosed, day) {
      let temp = this.openHours;
      if (isClosed) {
        delete temp[day];
      } else {
        temp[day] = [["08:00", "22:00"]];
      }
      this.openHours = { ...temp };
    },
    updateHours(newVal, day) {
      if (newVal === undefined) {
        return;
      }
      let temp = this.openHours;
      temp[day] = [newVal];
      this.openHours = { ...temp };
    }
  }
};
</script>
<style lang="scss" scoped>
.property-header {
  text-align: center;
}
</style>
