import store from "@/store/store.js";
import maplibregl from "maplibre-gl";
import booleanIntersects from "@turf/boolean-intersects";
import { polygon, point } from "@turf/helpers";
import bbox from "@turf/bbox";
import center from "@turf/center";
import bboxPolygon from "@turf/bbox-polygon";
import distance from "@turf/distance";
import MapConstants from "@/constants/mapConstants";

export default class MapHelpers {
  static fitBounds(coordOrCoordArr, options) {
    let map;
    if (options?.map) {
      map = options.map;
    } else {
      map = store.getters["MAP/map"];
    }
    if (!map) {
      console.warn("Cannot call fitBounds - map is not initialized yet");
      return;
    }

    const coordArr = this.handleCoordinates(coordOrCoordArr);
    if (!coordOrCoordArr || !Array.isArray(coordOrCoordArr) || !coordOrCoordArr.length) {
      console.warn("fitBounds failed - invalid coordinate or coordinate array provided");
      return;
    }
    let bounds;
    try {
      bounds = coordArr.reduce(function (allBounds, coord) {
        try {
          return allBounds.extend(coord);
        } catch (e) {
          console.debug(e.message, coord);
        }
      }, new maplibregl.LngLatBounds(coordArr[0], coordArr[0]));
    } catch (e) {
      console.debug(e.message, coordArr);
    }

    try {
      // stop method + setTimeout(0) is used to stop running animation.
      // Otherwise when fitBounds is called again before previous animation ends, both old and new animations halt
      // TODO: check this part again if animation interruption happens
      map.stop();

      setTimeout(() => {
        map.fitBounds(bounds, {
          padding: 100,
          duration: 500
        });
      }, 200);
    } catch (e) {
      console.debug(e.message, bounds);
    }
  }

  static jumpToPoint(coordinate, options) {
    const map = store.getters["MAP/map"];
    if (!map) {
      console.warn("Cannot call fitBounds - map is not initialized yet");
      return;
    }
    map.jumpTo({ center: coordinate, zoom: options?.zoom || 21 });
  }

  /**
   * Convert different type of coordinates to supported style,
   * example: [[1.23]] => [[1.23, 1.23]]
   */
  static handleCoordinates(coordOrCoordArr) {
    if (!coordOrCoordArr || !Array.isArray(coordOrCoordArr) || !coordOrCoordArr.length) {
      return coordOrCoordArr;
    }
    const isCoordArr = Array.isArray(coordOrCoordArr[0]);
    let coordArr = isCoordArr ? coordOrCoordArr : [coordOrCoordArr];

    return coordArr.map((coord) => {
      if (coord.length === 1) {
        const validCoord = [coord[0], coord[0]];
        console.warn("Invalid coordinta - single point of coordinate: ", coord, "defaults to: ", validCoord);
        coord = validCoord;
      }
      return coord.map((num) => {
        return typeof num === "string" ? parseFloat(num) : num;
      });
    });
  }

  static toggleAllLayers(typeCodesObj) {
    for (const [typeCode, typeCodeObj] of Object.entries(typeCodesObj)) {
      if (typeCode) {
        this.toggleLayer(typeCode, typeCodeObj.isVisible);
      }
    }
  }

  static toggleLayer(typeCode, isVisible) {
    const map = store.getters["MAP/map"];
    const fillLayerId = `fill_${typeCode}_ptr`;
    const symbolLayerId = `symbol_${typeCode}_ptr`;
    if (map.getLayer(fillLayerId)) {
      map.setLayoutProperty(fillLayerId, "visibility", isVisible ? "visible" : "none");
    }
    if (map.getLayer(symbolLayerId)) {
      map.setLayoutProperty(symbolLayerId, "visibility", isVisible ? "visible" : "none");
    }
  }

  static checkIfCoordinatesIntersect(coordArr1, coordArr2) {
    try {
      const feature1 = MapHelpers.coordToFeature(coordArr1);
      const feature2 = MapHelpers.coordToFeature(coordArr2);
      return booleanIntersects(feature1, feature2);
    } catch (err) {
      console.debug("Failed to check if boxes intersect - ", err);
    }
    return false;
  }

  static coordToFeature(coordArr) {
    try {
      // TODO: detect and handle line for future uses
      if (typeof coordArr[0] === "number") {
        return point(coordArr);
      } else {
        return polygon(coordArr);
      }
    } catch (error) {
      console.debug("Failed to convert coord to feature - ", error);
      console.debug("Coordinate: ", coordArr);
    }
  }

  static getViewportCoordinates() {
    try {
      const map = store.getters["MAP/map"];
      const canvas = map.getCanvas();
      const w = canvas.width;
      const h = canvas.height;
      const cUL = map.unproject([0, 0]).toArray();
      const cUR = map.unproject([w, 0]).toArray();
      const cLR = map.unproject([w, h]).toArray();
      const cLL = map.unproject([0, h]).toArray();
      const coordinates = [cUL, cUR, cLR, cLL, cUL];
      return [coordinates];
    } catch (err) {
      console.debug("Failed to check if boxes intersect - ", err);
    }
    return [];
  }

  static eyeIconClicked(list, contentName) {
    const typeCode = list.features[0].properties.typeCode;
    const isVisible = store.getters["CONTENT/getTypeCodeObjVisibility"](typeCode, contentName);
    if (isVisible === undefined) {
      return;
    }
    store.commit("CONTENT/TOGGLE_TYPE_CODE_VISIBILITY", {
      typeCode: typeCode,
      contentName: contentName,
      isVisible: !isVisible
    });
    list.isVisible = !isVisible;
    store.dispatch("CONTENT/SET_VISIBILITY_OF_TYPE_CODE", { contentName, isVisible: list.isVisible, typeCode });
    this.toggleLayer(typeCode, list.isVisible);
  }

  static filterList(filterText, featuresToHighlight) {
    //TODO: Highlight on filter. Currently it highlights all features on refresh data
    // store.dispatch("MAP/UNHIGHLIGHT_ALL_FEATURES");
    if (filterText === undefined || filterText === null || filterText === "") {
      store.dispatch("CONTENT/FILTER_TEXT", "");
    } else {
      store.dispatch("CONTENT/FILTER_TEXT", filterText);
      // const highligtFeatures = [];
      // featuresToHighlight.forEach((list) => highligtFeatures.push(...list.features));
      // store.dispatch("MAP/HIGHLIGHT_FEATURES", highligtFeatures);
    }
  }

  static async initializeContentTypes(clientId, siteId, buildingId, levelId) {
    await Promise.all([
      store.dispatch("SET_TAXONOMY"),
      store.dispatch("CONTENT/SET_CLIENTS"),
      store.dispatch("CONTENT/SET_SITES", { clientId }),
      store.dispatch("CONTENT/SET_BUILDINGS", { siteId }),
      store.dispatch("CONTENT/SET_LEVELS", { buildingId })
    ]);
    store.dispatch("MAP/SET_CURRENT_CLIENT", { client: clientId });
    store.dispatch("MAP/SET_CURRENT_SITE", { site: siteId });
    store.dispatch("MAP/SET_CURRENT_BUILDING", { building: buildingId });
    store.dispatch("MAP/DISPLAY_BUILDING_BORDERS", { enableCurrentBuildingFilter: true });

    await store.dispatch("CONTENT/SET_CONTENTS", {
      forceUpdate: true,
      siteInternalIdentifier: siteId,
      buildingInternalIdentifier: buildingId,
      levelIndex: levelId
    });
    await store.dispatch("MAP/SET_CURRENT_LEVEL", { level: levelId });
    store.dispatch("CONTENT/GENERATE_TYPE_CODE_DICTS");
  }

  /**
   * Zooms to "bboxToZoomAt" if "bboxToCheckIfWithinView" is visible on screen
   */
  static handleMapZoom({ bboxToCheckIfWithinView, bboxToZoomAt }) {
    if (!bboxToCheckIfWithinView) {
      console.debug("Cannot check if bbox is within view - will not zoom");
      return false;
    }
    if (!bboxToZoomAt) {
      console.debug("Invalid bboxToZoomAt - will zoom to bboxToCheckIfWithinView");
      MapHelpers.fitBounds(bboxToCheckIfWithinView[0]);
      return;
    }

    const isFeatureWithinView = MapHelpers.checkIfCoordinatesIntersect(
      bboxToCheckIfWithinView,
      MapHelpers.getViewportCoordinates()
    );

    if (!isFeatureWithinView) {
      MapHelpers.fitBounds(bboxToZoomAt);
    }
  }

  static enableMapInteractions(options) {
    let map;
    if (options?.map) {
      map = options.map;
    } else {
      map = store.getters["MAP/map"];
    }
    if (!map) {
      console.warn("Cannot enable map interactions - map is not initialized yet");
      return;
    }

    map.setBearing(0);
    map.setPitch(0);
    map.touchZoomRotate.disable();
    map.dragRotate.disable();
    map["keyboard"].disable();
  }

  static disableMapInteractions(options) {
    let map;
    if (options?.map) {
      map = options.map;
    } else {
      map = store.getters["MAP/map"];
    }
    if (!map) {
      console.warn("Cannot disable map interactions - map is not initialized yet");
      return;
    }

    map.dragRotate.enable();
    map.touchZoomRotate.enable();
    map["keyboard"].enable();
  }

  static toggleFeatureHighlight(map, hoveredStateId, hovered) {
    if (hoveredStateId !== undefined) {
      map.setFeatureState({ source: MapConstants.SOURCE, id: hoveredStateId }, { hover: hovered });
    }
  }

  static highlightContent(map, mapObjects, hoveredStateId, featureId) {
    let hoveredFeature = mapObjects.find((feature) => {
      return feature?.properties?.fid === featureId;
    });
    if (hoveredFeature) {
      if (hoveredStateId !== undefined) {
        this.toggleFeatureHighlight(map, hoveredStateId, false);
        hoveredStateId = undefined;
      }
      hoveredStateId = hoveredFeature?.id;
      this.toggleFeatureHighlight(map, hoveredStateId, true);
      return hoveredStateId;
    } else {
      this.toggleFeatureHighlight(map, hoveredStateId, false);
      return undefined;
    }
  }

  static unhighlightContent(map, hoveredStateId, hovered) {
    this.toggleFeatureHighlight(map, hoveredStateId, hovered);
  }

  static addAnchorDashSourceAndLayer() {
    const map = store.getters["MAP/map"];
    const dashedSource = {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: []
      }
    };
    if (!map.getSource("anchors-dashed-line-source")) {
      map.addSource("anchors-dashed-line-source", dashedSource);
    }
    const dashedLineLayer = {
      id: "anchors-dashed-line",
      type: "line",
      source: "anchors-dashed-line-source",
      layout: {
        "line-join": "round",
        "line-cap": "round"
      },
      paint: {
        "line-color": "#346df1",
        "line-width": 4,
        "line-dasharray": [1, 2]
      }
    };
    if (!map.getLayer("anchors-dashed-line")) {
      map.addLayer(dashedLineLayer);
    }
  }

  static removeAnchorDashSourceAndLayer() {
    const map = store.getters["MAP/map"];
    if (map.getLayer("anchors-dashed-line")) {
      map.removeLayer("anchors-dashed-line");
    }

    if (map.getSource("anchors-dashed-line-source")) {
      map.removeSource("anchors-dashed-line-source");
    }
  }

  static addFloorOutlineBorders(features) {
    const map = store.getters["MAP/map"];
    if (!map) {
      console.warn("Cannot call addFloorOutlineBorders - map is not initialized yet");
      return;
    }
    if (!features?.length) {
      return;
    }
    const geojson = {
      type: "FeatureCollection",
      features: features
    };
    const bboxOfGeojson = bbox(geojson);
    const featureBbox = bboxPolygon(bboxOfGeojson);
    let point1 = featureBbox?.geometry?.coordinates?.[0]?.[0];
    let point2 = featureBbox?.geometry?.coordinates?.[0]?.[1];
    // let point3 = featureBbox?.geometry?.coordinates?.[0]?.[2];
    let point4 = featureBbox?.geometry?.coordinates?.[0]?.[3];

    const turfPoint1 = point(point1);
    const turfPoint2 = point(point2);
    const turfPoint4 = point(point4);
    const distanceUnit = this.getUnit();
    const distance1 = `${distance(turfPoint1, turfPoint2, { units: distanceUnit.units }).toFixed(2)} ${
      distanceUnit.abbr
    }`;
    const distance2 = `${distance(turfPoint1, turfPoint4, { units: distanceUnit.units }).toFixed(2)} ${
      distanceUnit.abbr
    }`;

    // TODO: If not needed remove
    const centerOfDistance1 = center({ type: "FeatureCollection", features: [turfPoint1, turfPoint2] });
    const centerOfDistance2 = center({ type: "FeatureCollection", features: [turfPoint1, turfPoint4] });

    const distance1Feature = {
      type: "Feature",
      properties: {
        name: distance1
      },
      geometry: {
        type: "Point",
        coordinates: centerOfDistance1.geometry.coordinates
      }
    };

    const distance2Feature = {
      type: "Feature",
      properties: {
        name: distance2
      },
      geometry: {
        type: "Point",
        coordinates: centerOfDistance2.geometry.coordinates
      }
    };

    const line1 = {
      type: "Feature",
      properties: { name: distance1 },
      geometry: {
        type: "LineString",
        coordinates: [point1, point2]
      }
    };

    const line2 = {
      type: "Feature",
      properties: { name: distance2 },
      geometry: {
        type: "LineString",
        coordinates: [point4, point1]
      }
    };

    const lineGeojson = {
      type: "FeatureCollection",
      features: [line1, line2]
    };

    const metersGeojson = {
      type: "FeatureCollection",
      features: [distance1Feature, distance2Feature]
    };

    const cadMetersSource = map.getSource("cad-meters");
    if (!cadMetersSource) {
      map.addSource("cad-meters", {
        type: "geojson",
        data: lineGeojson
      });
    } else {
      cadMetersSource.setData(lineGeojson);
    }

    const cadMetersSymbolSource = map.getSource("cad-meters-symbol");
    if (!cadMetersSymbolSource) {
      map.addSource("cad-meters-symbol", {
        type: "geojson",
        data: metersGeojson
      });
    } else {
      cadMetersSymbolSource.setData(metersGeojson);
    }

    if (!map.getLayer("cad-meters")) {
      map.addLayer({
        id: "cad-meters",
        type: "line",
        source: "cad-meters",
        paint: {
          "line-color": "rgba(52, 109, 241, 1)",
          "line-opacity": 1,
          "line-width": 5,
          "line-dasharray": [1, 2],
          "line-offset": 20
        },
        layout: {
          "line-cap": "square",
          "line-join": "miter"
        }
      });
    }

    if (!map.getLayer("cad-meters-symbol")) {
      map.addLayer({
        id: "cad-meters-symbol",
        type: "symbol",
        source: "cad-meters-symbol",
        layout: {
          "text-field": "{name}",
          visibility: "visible",
          "text-line-height": 1,
          "text-font": ["Metropolis Bold"],
          // "symbol-placement": "line-center",
          "text-size": 16,
          "text-pitch-alignment": "viewport",
          "text-rotation-alignment": "viewport",
          "text-offset": [0, 0]
        },
        paint: {
          "text-color": "rgba(255, 255, 255, 1)",
          "text-halo-color": "rgba(52, 109, 241, 1)",
          "text-halo-width": 5,
          "text-translate": [0, 2],
          "text-halo-blur": 0
        }
      });
    }
  }
  static getUnit() {
    const lang = this.getLang();
    if (lang === "en-US") {
      return { units: "feet", abbr: "ft" };
    }
    return { units: "meters", abbr: "m" };
  }
  static getLang() {
    if (navigator.languages != undefined) return navigator.languages[0];
    return navigator.language;
  }
  static removeFloorOutlineBorders() {
    const map = store.getters["MAP/map"];
    if (!map) {
      console.warn("Cannot call removeFloorOutlineBorders - map is not initialized yet");
      return;
    }
    if (map.getLayer("cad-meters-symbol")) {
      map.removeLayer("cad-meters-symbol");
    }

    if (map.getLayer("cad-meters")) {
      map.removeLayer("cad-meters");
    }

    if (map.getSource("cad-meters-symbol")) {
      map.removeSource("cad-meters-symbol");
    }

    if (map.getSource("cad-meters")) {
      map.removeSource("cad-meters");
    }
  }
}
