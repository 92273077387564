<template>
  <div id="map-ui-building-level-selector" class="px-3">
    <v-col id="building-selector-column" class="px-0">
      <div id="map-ui-building-selector-container">
        <v-autocomplete
          id="map-ui-building-selector"
          v-model="selectedBuildingId"
          :items="buildings"
          item-text="buildingTitle"
          item-value="buildingInternalIdentifier"
          dense
          hide-details="auto"
          attach="#map-ui-building-selector-container"
          append-icon=""
        >
          <template #append>
            <PtrIcon icon="caret-down" />
          </template>
        </v-autocomplete>
      </div>
    </v-col>
    <v-col id="level-selector-column" class="px-0">
      <div id="map-ui-level-selector-container">
        <v-autocomplete
          id="map-ui-level-selector"
          v-model="selectedLevelId"
          :items="selectedBuilding?.levels"
          item-text="levelShortTitle"
          item-value="levelIndex"
          dense
          hide-details="auto"
          attach="#map-ui-level-selector-container"
          append-icon=""
        >
          <template #append>
            <PtrIcon icon="caret-down" />
          </template>
        </v-autocomplete>
      </div>
    </v-col>
  </div>
</template>

<script>
import PtrIcon from "@/components/shared/PtrIcon.vue";
import { mapState } from "vuex";

export default {
  name: "BuildingLevelSelector",
  components: { PtrIcon },
  data: () => ({
    selectedBuildingId: undefined,
    selectedLevelId: undefined,
    selectedBuilding: undefined
  }),
  computed: {
    ...mapState("CONTENT", ["buildings", "sites"]),
    ...mapState("MAP", ["isMapReady", "currentBuilding", "currentLevel"]),
    currentSiteObject() {
      return this.sites?.find((site) => site.siteInternalIdentifier === Number(this.$route.params.siteId));
    }
  },
  watch: {
    async selectedBuildingId(newVal, oldVal) {
      this.selectedBuilding = this.currentSiteObject.buildings?.find(
        (building) => building.buildingInternalIdentifier === this.selectedBuildingId
      );

      if (this.selectedBuildingId !== Number(this.$route.params.buildingId)) {
        this.levels = this.selectedBuilding.levels;
        if (this.selectedBuilding.levels?.[0].levelIndex) {
          this.selectedLevelId = this.selectedBuilding.levels?.[0].levelIndex;
        }
      }

      if (oldVal !== undefined && newVal !== oldVal) {
        this.$store.dispatch("MAP/SET_CURRENT_BUILDING", { building: this.selectedBuildingId });
        if (this.selectedBuilding.levels?.[0].levelIndex !== undefined) {
          this.selectedLevelId = this.selectedBuilding.levels?.[0].levelIndex;
        }
      }
    },
    async selectedLevelId() {
      await this.$store.dispatch("MAP/SET_CURRENT_LEVEL", { level: this.selectedLevelId });
    },

    currentBuilding() {
      this.selectedBuildingId = this.currentBuilding;
      this.$store.dispatch("MAP/DISPLAY_BUILDING_BORDERS", { enableCurrentBuildingFilter: true });
    },
    currentLevel() {
      this.selectedLevelId = this.currentLevel;
    }
  },
  created() {
    const { buildingId, levelId } = this.$route.params;
    this.selectedBuildingId = Number(buildingId);
    this.selectedLevelId = Number(levelId);
  },
  methods: {
    setMapUI() {
      this.$store.commit("MAP/SHOW_UI_ICON", { iconName: "target" });
      this.$store.commit("MAP/SHOW_UI_ICON", { iconName: "zoom" });
    }
  }
};
</script>

<style lang="scss" scoped>
#map-ui-building-level-selector {
  pointer-events: auto;
  width: 245px;
  position: absolute;
  font-size: 16px;
  height: 44px;
  top: 24px;
  left: calc(50% - 122px);
  z-index: 9;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  box-shadow: 0px 4px 8px rgba(23, 25, 28, 0.16);
}
#map-ui-level-selector-container,
#map-ui-building-selector-container {
  position: relative;
  ::v-deep {
    .v-input__slot:after {
      transform: scaleX(0);
    }
  }
}
#building-selector-column {
  flex-grow: 3;
}
#level-selector-column {
  flex-grow: 1;
}
::v-deep {
  .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
    border: none;
  }
  .v-autocomplete__content {
    min-width: 60px !important;
    &.v-menu__content {
      border-radius: 8px;
    }
  }
  .v-sheet {
    &.v-list {
      border-radius: 8px;
    }
  }
  .v-list-item__content {
    max-width: 100%;
  }
  .v-text-field {
    margin-top: 0;
  }

  .v-autocomplete.v-select.v-input--is-focused input {
    min-width: 25px;
  }

  .v-input input {
    color: var(--v-primary-base) !important;
    font-size: 15px;
  }

  .v-input__append-inner {
    width: 28px;
    height: 28px;
    cursor: pointer;
    > div {
      margin: auto;
      img {
        height: 8px;
      }
    }
  }
}
</style>
