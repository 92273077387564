<template>
  <v-expansion-panels v-model="defaultOpenPanelIndex" class="content-add-edit-expansion-panels" flat>
    <v-expansion-panel active-class="expanded" class="mt-0">
      <v-expansion-panel-header>
        <PtrIcon class="expansion-panel-header-icon" icon="information" />
        <div class="expansion-panel-header-text">{{ $t(`${translationPath}details`) }}</div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form v-model="isDetailsValid" class="mb-5">
          <v-row class="ma-0">
            <v-col class="py-0">
              <v-autocomplete
                id="content-type-input"
                v-model="selectedType"
                :items="typeList"
                item-text="title"
                item-value="code"
                outlined
                dense
                :label="$t(`${translationPath}types`)"
                hide-details="auto"
                :menu-props="{ closeOnContentClick: true }"
                :rules="[autoCompleteRequired]"
              >
                <template #item="{ item }">
                  <v-list-item @click="onTypeSelected(item.code)">
                    <v-list-item-content>
                      <v-list-item-title> {{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template #append>
                  <PtrIcon icon="caret-down" />
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row v-if="shouldShowFeatureName" class="mt-2">
            <v-col class="py-0">
              <v-text-field
                id="name-input"
                v-model.trim="name"
                :rules="[rules.featureName]"
                :label="$t(featureTranslationPath + 'name-required')"
                hide-details="auto"
                outlined
                dense
                @keydown="setFormDirty"
              />
            </v-col>
          </v-row>
        </v-form>
        <AdditionalProperties
          ref="additionalPropertiesComponent"
          :properties="additionalProperties"
          @valid="(isValid) => (isPropertiesValid = isValid)"
          @propertyAdded="(item) => addNewProperty(item)"
          @propertyDeleted="(item) => deleteProperty(item)"
          @propertyUpdated="(item, newValue) => updateProperty(item, newValue)"
        ></AdditionalProperties>
        <GeometrySection
          v-if="selectedType"
          ref="geometrySection"
          :feature-id="featureId"
          :should-show-polygon-icon="shouldShowPolygonIcon"
          :should-show-point-icon="shouldShowPointIcon"
          :is-edit="isEdit"
          is-circle-enabled
        ></GeometrySection>
        <div class="form-footer mt-4">
          {{ $t(`${translationPath}required`) }}
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel active-class="expanded" class="mt-0">
      <v-expansion-panel-header>
        <PtrIcon class="expansion-panel-header-icon" icon="custom-integration" />
        <div class="expansion-panel-header-text">{{ $t(`${translationPath}custom-integration`) }}</div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <CustomIntegration
          ref="customIntegration"
          :extra-data-prop="extraData"
          :feature-id="featureId"
          @integrationUpdated="setFormDirty"
          @setFormDirty="setFormDirty"
        ></CustomIntegration>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <slot name="danger-zone"></slot>
  </v-expansion-panels>
</template>

<script>
import { mapState } from "vuex";
import GeometrySection from "@/components/mapDesigner/GeometrySection.vue";
import PointMode from "@/helpers/drawModes/PointMode";
import ValidationHelpers from "@/helpers/ValidationHelpers";
import ContentService from "@/services/ContentService";
import CustomToast from "@/components/shared/CustomToast.vue";
import CustomIntegration from "@/components/shared/CustomIntegration.vue";
import AdditionalProperties from "@/components/mapDesigner/AdditionalProperties.vue";
import PtrIcon from "@/components/shared/PtrIcon.vue";
import MapHelpers from "@/helpers/MapHelpers";
import PoiProperties from "@/constants/poiProperties";

const DISPLAY_MODE_UNAVAILABLE = "unavailable";
const DISPLAY_MODE_REQUIRED = "required";

export default {
  components: { GeometrySection, CustomIntegration, PtrIcon, AdditionalProperties },
  props: {
    featureId: [String, Number]
  },
  data: () => ({
    translationPath: "contents.mapDesigner.",
    featureTranslationPath: "contents.feature.",
    feature: {},
    selectedType: undefined,
    featureNameDisplayMode: "",
    featureDescriptionDisplayMode: "",
    featureKeywordDisplayMode: "",
    taxonomyType: "",
    name: undefined,
    isPropertiesValid: false,
    isDetailsValid: false,
    isEdit: false,
    defaultOpenPanelIndex: 0,
    extraData: {},
    additionalProperties: {},
    isUploadFailed: false
  }),
  computed: {
    ...mapState("CONTENT", ["pois", "mapObjects", "poiTypeCodesObj", "mapObjectsTypeCodesObj", "isFormDirty"]),
    ...mapState("MAP", [
      "mapDraw",
      "currentSite",
      "currentBuilding",
      "currentLevel",
      "drawnCoordinates",
      "mapMode",
      "isMapBorderEnabled"
    ]),
    taxonomy() {
      return this.$store.state.taxonomy;
    },
    typeList() {
      let suggestedTypeCodesArr = [];
      let suggestedTypes = [];
      let allTypes = [];

      suggestedTypeCodesArr = Object.keys(this.poiTypeCodesObj);
      suggestedTypes = Object.values(this.taxonomy).filter(
        (item) => item.properties.class === "poi" && suggestedTypeCodesArr.includes(item.code)
      );
      allTypes = Object.values(this.taxonomy).filter((item) => item.properties.class === "poi");

      let autoCompleteItems = [];
      if (suggestedTypes.length) {
        autoCompleteItems = [
          {
            header: "SUGGESTED TYPES"
          },
          ...suggestedTypes
        ];
      }
      return [
        ...autoCompleteItems,
        {
          header: "ALL TYPES"
        },
        ...allTypes
      ];
    },
    shouldShowFeatureName() {
      return this.featureNameDisplayMode !== DISPLAY_MODE_UNAVAILABLE;
    },
    shouldShowFeatureDescription() {
      return this.featureDescriptionDisplayMode !== DISPLAY_MODE_UNAVAILABLE;
    },
    shouldShowFeatureKeyword() {
      return this.featureKeywordDisplayMode !== DISPLAY_MODE_UNAVAILABLE;
    },
    rules() {
      return {
        featureName: (value) => {
          return ValidationHelpers.isRequired(value?.trim());
        },
        featureDescription: (value) => {
          return this.featureDescriptionDisplayMode === DISPLAY_MODE_REQUIRED
            ? ValidationHelpers.isRequired(value)
            : true;
        },
        featureKeywords: (value) => {
          return this.featureKeywordDisplayMode === DISPLAY_MODE_REQUIRED ? ValidationHelpers.isRequired(value) : true;
        },
        required: (value) => ValidationHelpers.isRequired(value)
      };
    },
    shouldShowPointIcon() {
      return this.taxonomyType?.properties?.shape?.includes("point");
    },
    shouldShowPolygonIcon() {
      return this.taxonomyType?.properties?.shape?.includes("polygon");
    }
  },
  watch: {
    featureId: {
      immediate: true,
      handler() {
        this.$store.dispatch("MAP/DRAWN_POLYGON_CHANGED", { feature: undefined, shouldKeepFormClean: true });
        this.$store.commit("MAP/DRAWN_COORDINATES", undefined);
        PointMode.clearPoints();
        const temp = this.mapObjects.find((mapObject) => mapObject.properties.fid === this.featureId);
        this.feature = structuredClone(temp);
        if (this.feature) {
          this.isEdit = true;
          this.selectedType = this.feature.properties.typeCode;
          this.name = this.feature.properties.name;
          this.setAdditionalProperties();
          this.extraData = this.feature.properties?.extra || this.feature.properties?.extraData;
          this.taxonomyType = this.taxonomy[this.selectedType];
          this.$refs?.geometrySection?.reset();
          this.$store.commit("MAP/DRAWN_COORDINATES", this.feature.geometry.coordinates);

          if (this.feature.geometry.type === "Polygon") {
            this.$store.dispatch("MAP/DRAWN_POLYGON_CHANGED", { feature: this.feature, shouldKeepFormClean: true });
          } else {
            PointMode.addPoint(this.drawnCoordinates || [], {
              draggable: false,
              fid: this.feature.properties.fid
            });
          }
        }
      }
    },
    selectedType() {
      this.taxonomyType = this.taxonomy[this.selectedType];
      this.parseTypeForInputs();
      this.featureNameDisplayMode = this.taxonomyType?.properties?.isTitleEnabled;
      this.featureDescriptionDisplayMode = this.taxonomyType?.properties?.isDescriptionEnabled;
      this.featureKeywordDisplayMode = this.taxonomyType?.properties?.keyword;
      if (this.isEdit && this.selectedType && this.feature.properties.typeCode !== this.selectedType) {
        this.$store.commit("CONTENT/IS_FORM_DIRTY", true);
      } else if (this.isEdit && this.feature.properties.typeCode === this.selectedType) {
        this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
      }
    },
    isPropertiesValid() {
      this.emitValidationStatusForForm();
    },
    isDetailsValid() {
      this.emitValidationStatusForForm();
    },
    drawnCoordinates() {
      this.emitValidationStatusForForm();
    },
    isMapBorderEnabled() {
      this.emitValidationStatusForForm();
    },
    additionalProperties() {
      if (Object.keys(this.additionalProperties).length === 0) {
        this.isPropertiesValid = true;
      }
    }
  },
  created() {
    MapHelpers.enableMapInteractions();
    this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
    this.parseTypeForInputs();
  },
  beforeDestroy() {
    MapHelpers.disableMapInteractions();
    this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
  },
  methods: {
    //TODO: Move to a helper
    autoCompleteRequired(value) {
      if (value === null || value === undefined) {
        this.isDetailsValid = false;
        // TODO: Move to language file
        return this.$t("contents.validations.type-must-be-selected");
      }
      this.isDetailsValid = true;
      return true;
    },
    async save() {
      let coordinates;
      try {
        coordinates = JSON.parse(this.drawnCoordinates || "[]");
      } catch (e) {
        coordinates = this.drawnCoordinates;
      }
      const isCoordinatesPolygon = Array.isArray(coordinates?.[0]);
      const isMultiPolygon = this.mapDraw.getAll()?.features?.[0]?.geometry?.type === "MultiPolygon";
      const geometryType = isCoordinatesPolygon ? "Polygon" : "Point";

      if (isMultiPolygon) {
        let isSuccess;
        await Promise.all(
          coordinates.map(async (coordinate, index) => {
            if (index === 0) {
              isSuccess = await this.createRequestAndPut(
                this.feature?.properties?.fid,
                this.name,
                {
                  type: geometryType,
                  coordinates: coordinate
                },
                true
              );
            } else {
              isSuccess = await this.createRequestAndPut(
                undefined,
                this.name + "#" + index,
                {
                  type: geometryType,
                  coordinates: coordinate
                },
                false
              );
            }
          })
        );
        return isSuccess;
      } else {
        return await this.createRequestAndPut(
          this.feature?.properties?.fid,
          this.name,
          {
            type: geometryType,
            coordinates: coordinates
          },
          true
        );
      }
    },
    async createRequestAndPut(fid, name, geometry, isEdit) {
      // updates inputs to which user entered a value but does not added properly because he/she did not press enter afterwards
      this.$refs.additionalPropertiesComponent.updateInputs();
      return await this.$nextTick().then(async () => {
        let extraData = this.$refs?.customIntegration?.getExtraData();
        if (!extraData && this.feature) {
          extraData = this.feature.properties?.extra || this.feature.properties?.extraData;
        }

        let requestProperties = await this.getRequestProperties();
        if (this.isUploadFailed) {
          this.isUploadFailed = false;
          return;
        }

        const request = {
          type: "Feature",
          properties: {
            typeCode: this.selectedType,
            fid: fid,
            sid: Number(this.currentSite),
            bid: Number(this.currentBuilding),
            lvl: Number(this.currentLevel),
            name: name?.trim(),
            extra: extraData,
            style: {},
            ...requestProperties
          },
          geometry: geometry
        };
        const response = await ContentService.putFeature(request);

        if (response?.createdTimestampUtcEpochSeconds) {
          this.$store.dispatch("CONTENT/UPDATE_FEATURE", { feature: request, isEdit: this.isEdit && isEdit });
          this.$store.dispatch("CONTENT/SET_LOCAL_CHANGES");
          const editMessage = `${request.properties.name || request.properties.fid} ${this.$t("contents.updated")}`;
          const createdMessage = `${request.properties.name || request.properties.fid} ${this.$t("contents.created")}`;
          this.$toast.success({
            component: CustomToast,
            props: { message: this.isEdit ? editMessage : createdMessage }
          });
          this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
          return true;
        } else {
          this.$toast.error({
            component: CustomToast,
            props: {
              message:
                response?.message ||
                `${request.properties.name || request.properties.fid} ${this.$t("contents.failed")}`
            }
          });
          return false;
        }
      });
    },
    async getRequestProperties() {
      const temp = {};
      const keys = Object.keys(this.additionalProperties);

      for await (const key of keys) {
        const propertyObject = PoiProperties.POI_PROPERTIES.find((propObj) => propObj.key === key);
        const value = this.additionalProperties[key];

        if (!propertyObject || value === undefined) {
          continue;
        }
        const requestKey = propertyObject.requestKey;

        if (requestKey === "logo" && typeof value !== "string") {
          const imageUrl = await this.uploadImage(value[0]);
          if (imageUrl) {
            temp[requestKey] = imageUrl;
          } else {
            this.handleUploadError("logo");
            break;
          }
        } else if (requestKey === "images" && typeof value[0] !== "string") {
          const imageUrls = await this.uploadImages(value);
          if (imageUrls) {
            temp[requestKey] = imageUrls;
          } else {
            this.handleUploadError("image");
            break;
          }
        } else if (requestKey === "price" || requestKey === "rating") {
          Object.assign(temp, value);
        } else if (propertyObject.valueType === "hyperlink") {
          this.handleHyperlinkProperty(temp, propertyObject, value);
        } else {
          temp[requestKey] = value;
        }
      }

      return temp;
    },

    async uploadImage(file) {
      try {
        const imageUrl = await this.uploadFile(file, "logo");
        return imageUrl;
      } catch (error) {
        this.isUploadFailed = true;
        this.handleUploadError("logo");
        return null;
      }
    },

    async uploadImages(files) {
      const imageUrls = [];
      for (const file of files) {
        const imageUrl = await this.uploadFile(file, "image");
        if (!imageUrl) {
          this.isUploadFailed = true;
          this.handleUploadError("image");
          return null;
        }
        imageUrls.push(imageUrl);
      }
      return imageUrls;
    },

    async uploadFile(file, imageType) {
      const request = {
        fileType: file?.name?.split(".").pop(),
        fileBase64: file?.base64,
        extra: {
          imageType,
          width: file?.width,
          height: file?.height
        }
      };
      const response = await ContentService.uploadImage(request);
      return response?.createdTimestampUtcEpochSeconds ? response.result?.imageUrl : null;
    },

    handleHyperlinkProperty(temp, propertyObject, button) {
      const buttons = temp["buttons"] || [];
      const buttonObject = {
        name: propertyObject.name,
        action: propertyObject.action,
        intent: button.value === undefined ? button : button.value,
        iconUrl: propertyObject.iconUrl
      };
      if (button.shouldOverride) {
        buttonObject.action = "custom";
      }
      temp["buttons"] = [...buttons, buttonObject];
      delete temp[propertyObject.key];
    },

    handleUploadError(imageType) {
      this.$toast.error({
        component: CustomToast,
        props: {
          message: this.$t(`${this.translationPath}${imageType}-upload-failed`)
        }
      });
    },

    async deleteContent() {
      const response = await ContentService.deleteFeature(this.feature);
      if (response?.createdTimestampUtcEpochSeconds) {
        this.$store.dispatch("CONTENT/UPDATE_FEATURE", { feature: this.feature, isDelete: true });
        this.$store.dispatch("CONTENT/SET_LOCAL_CHANGES");
        this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
        this.$toast.success({
          component: CustomToast,
          props: { message: this.$t(`${this.translationPath}content-deleted`) }
        });
        return true;
      } else {
        this.$toast.error({
          component: CustomToast,
          props: { message: response?.message || this.$t(`${this.translationPath}content-delete-failed`) }
        });
        return false;
      }
    },
    parseTypeForInputs() {
      this.featureNameDisplayMode = this.taxonomyType?.properties?.isTitleEnabled;
      this.featureDescriptionDisplayMode = this.taxonomyType?.properties?.isDescriptionEnabled;
      this.featureKeywordDisplayMode = this.taxonomyType?.properties?.keyword;
    },
    emitValidationStatusForForm() {
      this.$emit(
        "valid",
        this.isPropertiesValid &&
          this.isDetailsValid &&
          this.drawnCoordinates &&
          this.drawnCoordinates?.length !== 0 &&
          !this.isMapBorderEnabled
      );
    },
    onTypeSelected(type) {
      this.selectedType = type;
      this.setFormDirty();
    },
    setFormDirty() {
      this.$store.commit("CONTENT/IS_FORM_DIRTY", true);
    },
    /**
     * Called when an additional property is added from menu
     */
    addNewProperty(item) {
      const temp = { ...this.additionalProperties };
      temp[item.key] = undefined;
      this.additionalProperties = temp;
    },
    /**
     * Called when an additional property is deleted by clicking thrash icon
     */
    deleteProperty(propertyName) {
      const temp = this.additionalProperties;
      // additionalProperties is an object that consists of property name and value as key value pairs
      const index = Object.keys(this.additionalProperties).findIndex((property) => property === propertyName);
      if (index === -1) {
        return;
      }
      delete temp[propertyName];
      this.additionalProperties = { ...temp };
    },
    /**
     * Called when an additional property's value is updated
     */
    updateProperty(propertyName, newValue) {
      const value = typeof newValue === "string" ? newValue.trim() : newValue;
      const temp = { ...this.additionalProperties };
      temp[propertyName] = value;
      this.additionalProperties = temp;
    },
    /**
     * Sets data additionalProperties which is an object that consists of property name and value as key value pairs
     * Called when featureId changed
     */
    setAdditionalProperties() {
      const additionalProperties = {};
      const validKeys = PoiProperties.POI_PROPERTIES.map((propertyObject) => propertyObject.requestKey);
      Object.keys(this.feature.properties).forEach((propName) => {
        // Required properties are excluded (["typeCode", "fid", "sid", "bid", "lvl", "name", "extra", "style"])
        if (
          !validKeys.find((property) => property === propName) &&
          !PoiProperties.SECONDARY_PROPERTIES.find((property) => property === propName)
        ) {
          return;
        }

        const value = this.feature.properties[propName];
        if (value === "" || value === [] || value === null || value === undefined) {
          return;
        }
        // poi.properties.buttons is an array oj objects.
        //each button object consists of  name, action and intent key&values

        if (propName === "buttons") {
          this.feature.properties.buttons.forEach((button) => {
            const propertyObject = PoiProperties.POI_PROPERTIES.find(
              (propertyObject) => propertyObject.name === button.name
            );
            //each button added to additionalProperties with its requestKey (the key that is used at while creating request) and intent(href Value)
            if (button.name === "Book" || button.name === "Order") {
              additionalProperties[propertyObject.requestKey] = {
                value: button.intent,
                shouldOverride: button.action === "custom"
              };
            } else {
              additionalProperties[propertyObject.requestKey] = button.intent;
            }
          });
          return;
        }
        if (propName.toLowerCase().includes("rating")) {
          if (additionalProperties.rating) {
            additionalProperties.rating[propName] = value;
          } else {
            additionalProperties.rating = {};
            additionalProperties.rating[propName] = value;
          }

          return;
        }
        additionalProperties[propName] = value;
      });
      this.additionalProperties = additionalProperties;
    }
  }
};
</script>
