<template>
  <div id="map-ui-building-site-selector" :class="{ 'is-expanded': isExpanded == 0 }">
    <v-expansion-panels v-model="isExpanded" flat>
      <v-expansion-panel>
        <v-expansion-panel-header class="building-site-selector-content-header">
          <v-btn
            v-show="shouldEnableBackBtn"
            color="white"
            class="back-button v-btn--square"
            small
            icon
            depressed
            plain
            @click.stop="goBack"
          >
            <PtrIcon icon="caret-left" />
          </v-btn>
          <div style="width: 100%">
            <div id="title" key="title">{{ title }}</div>
            <div id="subtitle" key="subtitle">{{ subtitle }}</div>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-text-field
            v-if="contentList.length > 5"
            v-model="filterText"
            :label="$t('filter')"
            class="filter"
            outlined
            dense
            hide-details
          >
            <template #append>
              <PtrIcon
                v-if="filterText.length"
                class="mr-2"
                icon="cancel-circle"
                style="cursor: pointer"
                @click.native="filterText = ''"
              />
              <v-icon>mdi-magnify</v-icon>
            </template>
          </v-text-field>
          <v-list v-if="filteredContentList.length" class="building-site-selector-list" dense>
            <v-list-item
              v-for="(contentItem, i) in filteredContentList"
              :key="i"
              class="building-site-selector-content-item"
              dense
              :ripple="false"
              @click.stop="selectItem(contentItem.id)"
            >
              <v-list-item-content>{{ contentItem.title }} </v-list-item-content>

              <v-list-item-action>
                <v-btn
                  v-if="shouldShowSelectBtn"
                  class="v-btn--square select-btn"
                  :class="{ selected: selectedId === contentItem.id }"
                  small
                  icon
                  depressed
                  plain
                >
                  <PtrIcon icon="selected" />
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-list v-else>
            <v-list-item style="justify-content: center"> No {{ contentName }}s </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import PtrIcon from "@/components/shared/PtrIcon.vue";

export default {
  components: {
    PtrIcon
  },
  props: {
    title: String,
    subtitle: String,
    contentList: { type: Array, default: () => [] },
    selectedId: [Number, String],
    shouldShowSelectBtn: { type: Boolean, default: false },
    shouldEnableBackBtn: { type: Boolean, default: false },
    contentName: { type: String, default: "" }
  },

  data: () => ({
    isExpanded: undefined,
    filterText: ""
  }),

  computed: {
    filteredContentList() {
      return this.contentList.filter((contentItem) => {
        return contentItem.title.toLowerCase().includes(this.filterText.toLowerCase());
      });
    }
  },

  watch: {
    contentList() {
      this.filterText = "";
    }
  },

  methods: {
    selectItem(id) {
      this.$emit("content-item-selected", id);
    },
    goBack() {
      this.$emit("back-clicked");
    }
  }
};
</script>

<style lang="scss" scoped>
#map-ui-building-site-selector {
  pointer-events: auto;
  width: 260px;
  overflow: hidden;
  position: relative;
  font-size: 16px;

  .filter {
    padding: 0 8px;
  }

  .building-site-selector-list {
    padding: 0;
    max-height: 50vh;
    overflow-y: auto;

    .building-site-selector-content-item:hover {
      background-color: var(--v-primary-lighten5);
    }

    .v-list-item__action {
      height: 28px;
    }
  }

  &.is-expanded .building-site-selector-content-header {
    .back-button {
      display: flex;
    }
    #title {
      font-size: 16px !important;
      font-weight: bold;
    }
    #subtitle {
      display: none;
    }
  }

  .building-site-selector-content-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 60px;
    line-height: 16px;

    #title {
      display: flex;
      align-items: center;
      margin-right: 30px;
      line-height: 22px;
    }
    #subtitle {
      padding-top: 6px;

      &:empty {
        padding-top: 0px;
      }
    }

    #title,
    #subtitle {
      padding-left: 8px;
      transition: all 0.3s ease-in-out;
      font-size: 13px !important;
    }

    #subtitle {
      color: var(--v-primary-base);
    }
    .back-button {
      display: none;
      box-shadow: none !important;
      margin-right: 0 !important;
    }
  }
  ::v-deep {
    .v-expansion-panel-content__wrap {
      padding: 8px 0 !important;
    }

    .v-expansion-panel-header {
      padding: 8px 16px !important;

      .v-expansion-panel-header__icon {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
      }

      .list-item {
        padding: 0 !important;
      }
    }
  }
}

.select-btn {
  display: none;
}
.building-site-selector-content-item:hover .select-btn,
.select-btn.selected {
  display: flex;
}
</style>
